<h1 mat-dialog-title i18n="@@chartSettingsTitle">Chart bearbeiten</h1>
<div class="dialog-content" mat-dialog-content #dialogContent>

  <div class="settings">

    <div class="toggleGroup">
      <h5 i18n="@@chartSettingsAggregation">Chart</h5>

      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style" [value]="data.chartItem.type"
        (change)="data.chartItem.type = $event.value; compileChart(true);">
        <mat-button-toggle [value]="'table'" i18n="@@chartSettingsViewTable">Tabelle
        </mat-button-toggle>
        <mat-button-toggle [value]="'multiline'" i18n="@@chartSettingsViewMultiSeries">Multi-Series-Linechart
        </mat-button-toggle>
        <mat-button-toggle [value]="'pixel'" i18n="@@chartSettingsViewAgegroups">Altersgruppen
        </mat-button-toggle>
        <mat-button-toggle [value]="'stackedareaicu'" i18n="@@chartSettingsViewStackedAreaIcu">Bettenauslastung
          (kategorisch)
        </mat-button-toggle>
      </mat-button-toggle-group>

      <p class="hint" i18n="@@chartSettingsChartTypeHint">Was möchtest du gerne sehen?</p>
    </div>

    <div class="toggleGroup" *ngIf="!hidden.has('type')">
      <h5 i18n="@@chartSettingsType">Art</h5>
      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.type" (change)="data.chartItem.config.type= $event.value; compileChart(true)">
        <mat-button-toggle [disabled]="disabled.has('type.cases')" i18n="@@chartSettingsTypeSick" [value]="eType.cases">
          Positiv Getestet
        </mat-button-toggle>
        <mat-button-toggle [disabled]="disabled.has('type.deaths')" i18n="@@chartSettingsTypeDeath"
          [value]="eType.deaths">Todesfälle
        </mat-button-toggle>
        <mat-button-toggle [disabled]="disabled.has('type.patients')" i18n="@@chartSettingsTypePatients"
          [value]="eType.patients">Patienten
        </mat-button-toggle>
        <mat-button-toggle [disabled]="disabled.has('type.patientsVentilated')"
          i18n="@@chartSettingsTypePatientsVentilated" [value]="eType.patientsVentilated">Patienten (beatmet)
        </mat-button-toggle>
        <mat-button-toggle [disabled]="disabled.has('type.bedOccupancyPercent')"
          i18n="@@chartSettingsTypeBedOccupancyPercent" [value]="eType.bedOccupancyPercent">Bettenauslastung (%)
        </mat-button-toggle>
        <mat-button-toggle [disabled]="disabled.has('type.bedOccupancy')"
        i18n="@@chartSettingsTypeBedOccupancy" [value]="eType.bedOccupancy">Bettenauslastung
      </mat-button-toggle>
      </mat-button-toggle-group>

      <p class="hint" i18n="@@chartSettingsTypeHint">Welche Daten möchtest du gerne sehen?</p>
    </div>
  </div>

  <app-region-selector style="margin-top: 20px;" [selectedRegions]="data.chartItem.dataRequest"
    (selectedRegionsChange)="data.chartItem.dataRequest = $event; compileChart();"></app-region-selector>

  <div *ngIf="!pleaseWait && data.chartItem.dataRequest.length > 0">
    <app-multi-line-chart *ngIf="data.chartItem.type === 'multiline'" [dataAndOptions]="data.chartItem._compiled">
    </app-multi-line-chart>

    <app-pixel-chart *ngIf="data.chartItem.type === 'pixel'" [dataAndOptions]="data.chartItem._compiled">
    </app-pixel-chart>

    <app-table-overview *ngIf="data.chartItem.type === 'table'" [tableData]="data.chartItem._compiled">
    </app-table-overview>

    <app-stacked-area-icu-chart *ngIf="data.chartItem.type === 'stackedareaicu'"
      [dataAndOptions]="data.chartItem._compiled"></app-stacked-area-icu-chart>
  </div>

  <h2 style="text-align: center;" *ngIf="pleaseWait" i18n="@@pleaseWaitLoading">Bitte warten... lädt</h2>

  <h2 style="text-align: center;" *ngIf="!pleaseWait && data.chartItem.dataRequest.length === 0"
    i18n="@@chartSettingsMinimumOneRegion" class="error">Es muss mindestens eine Region ausgewählt werden.</h2>



  <div class="autoconfig-wrapper" *ngIf="hasAutoConfig">
    <button mat-raised-button color="autoconfigure" (click)="loadAutoConfig()"
      i18n="@@chartSettingsAutoConfigureButton">
      <mat-icon>auto_fix_high</mat-icon> Automatische Konfiguration
    </button>

    <mat-icon class="help" matTooltipPosition="right" i18n-matTooltip="@@chartSettingsAutoConfigureTooltip"
      matTooltip="Mit der Autokonfiguration werden die Einstellungen für den Chart und die Art der Daten mit den von uns empfohlenen Einstellungen für die beste Lesbarkeit konfiguriert.">
      help</mat-icon>
  </div>

  <div class="settings">



    <div class="toggleGroup" *ngIf="!hidden.has('dataSource')">
      <h5 i18n="@@chartSettingsDataSource">Daten Quelle</h5>

      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.dataSource"
        (change)="data.chartItem.config.dataSource = $event.value; compileChart()"
        [disabled]="disabled.has('dataSource')">
        <mat-button-toggle [disabled]="disabled.has('dataSource.rki')" [value]="eDataSource.rki"
          i18n="@@chartSettingsDataSourceRKI" i18n-matTooltip="@@tooltipDataSourceRKI"
          matTooltip="Robert Koch Institut">RKI
        </mat-button-toggle>
        <mat-button-toggle [disabled]="disabled.has('dataSource.risklayer')" [value]="eDataSource.risklayer"
          i18n="@@chartSettingsDataSourceRisklayer" i18n-matTooltip="@@tooltipDataSourceRisklayer"
          matTooltip="Live-Ansicht Risklayer Daten">Risklayer
        </mat-button-toggle>

        <mat-button-toggle [disabled]="disabled.has('dataSource.divi')" [value]="eDataSource.divi"
          i18n="@@chartSettingsDataSourceDivi" i18n-matTooltip="@@tooltipDataSourceSurvstat"
          matTooltip="DIVI Intensivregister">DIVI
        </mat-button-toggle>

        <mat-button-toggle [disabled]="disabled.has('dataSource.survstat')" [value]="eDataSource.survstat"
          i18n="@@chartSettingsDataSourceSurvstat">SurvStat &#64; RKI
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>


    <div class="toggleGroup" *ngIf="!hidden.has('normalization')">
      <h5 i18n="@@chartSettingsNorm">Normalisierung</h5>

      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.normalization"
        (change)="data.chartItem.config.normalization = $event.value; compileChart()"
        [disabled]="disabled.has('normalization')">
        <mat-button-toggle [disabled]="disabled.has('normalization.absolut')" i18n="@@chartSettingsNornNone"
          [value]="eNorm.absolut">Keine
          Normalisierung
        </mat-button-toggle>
        <mat-button-toggle [disabled]="disabled.has('normalization.per100k')" i18n="@@chartSettingsNornYes"
          [value]="eNorm.per100k">Je
          100.000 Einwohner
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="toggleGroup" *ngIf="!hidden.has('timeWindow')">
      <h5 i18n="@@chartSettingsTime">Zeitraum Veränderung</h5>

      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.timeWindow"
        (change)="data.chartItem.config.timeWindow = $event.value; compileChart()"
        [disabled]="disabled.has('timeWindow')">
        <mat-button-toggle i18n="@@chartSettingsTimeAll" [value]="eTime.all">Aktuell</mat-button-toggle>

        <mat-button-toggle i18n="@@chartSettingsTime24" onerror=" " [value]="eTime.twentyFourhours">24 Std.
        </mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsTime72" [value]="eTime.seventyTwoHours">72
          Std.
        </mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsTime7Days" [value]="eTime.sevenDays">7 Tage
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="toggleGroup" *ngIf="!hidden.has('timeAgg')">
      <h5 i18n="@@chartSettingsGranularity">Granularität (Zeit)</h5>
      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.timeAgg" (change)="data.chartItem.config.timeAgg = $event.value; compileChart()">
        <mat-button-toggle i18n="@@chartSettingsGranularityDaily" [value]="eGran.yearmonthdate">Täglich
        </mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsGranularityWeekly" [value]="eGran.yearweek"
          [disabled]="disabled.has('timeAgg.yearweek')">Wöchentlich</mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="toggleGroup" *ngIf="!hidden.has('ageGroupBinning')">
      <h5 i18n="@@chartSettingsAgeBinning">Altersgruppen</h5>
      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.ageGroupBinning"
        (change)="data.chartItem.config.ageGroupBinning = $event.value; compileChart()"
        [disabled]="disabled.has('ageGroupBinning')">
        <mat-button-toggle i18n="@@chartSettingsAgeGroupBinningAll" [value]="eAgeGroupBinning.all"
          [disabled]="disabled.has('ageGroupBinning.all')">Altersjahre</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsAgeGroupBinningFiveYears" [value]="eAgeGroupBinning.fiveyears"
          [disabled]="disabled.has('ageGroupBinning.fiveyears')">5 Jahre</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsAgeGroupBinningRKI" [value]="eAgeGroupBinning.rki">RKI Schema
        </mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsAgeGroupBinningManual" [value]="eAgeGroupBinning.manual">Manuell
        </mat-button-toggle>
      </mat-button-toggle-group>

      <div *ngIf="data.chartItem.config.ageGroupBinning === eAgeGroupBinning.manual">
        <mat-form-field style="width: 100%;">
          <mat-label i18n="@@chartSettingsAgeGroupBinningCustom">Benutzerdefinierte Altersgruppen</mat-label>
          <textarea matInput placeholder="0-3&#10;4-6&#10;...&#10;80-80" rows="6" [ngModel]="data.chartItem.config.ageGroupBinningCustom" (ngModelChange)="data.chartItem.config.ageGroupBinningCustom = $event; validateCustomAgeGroup()"></textarea>
          <pre class="error small" *ngIf="ageGroupsCustomError !== null">{{ ageGroupsCustomError }}</pre>
        </mat-form-field>

        <div style="margin-bottom: 5px;">
          <button mat-raised-button [disabled]="ageGroupsCustomError" (click)="compileChart()" i18n="@@chartSettingsUpdatePreview">Vorschau aktualisieren</button>
        </div>
      </div>
    </div>

    <div class="toggleGroup" *ngIf="!hidden.has('scaleType')">
      <h5 i18n="@@csaeDodScaling">Skalierung</h5>
      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.scaleType"
        (change)="data.chartItem.config.scaleType = $event.value; compileChart()">
        <mat-button-toggle i18n="@@chartSettingsScalingLinear" [value]="eScaleType.linear">Linear</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsScalingSqrt" [value]="eScaleType.sqrt">Quadratwurzel</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsScalingLog" [value]="eScaleType.symlog">Logarithmisch
        </mat-button-toggle>
      </mat-button-toggle-group>

      <p class="hint" i18n="@@chartSettingsScalingHint">Eine nicht-lineare Skalierung hebt niedrige Werte besser hervor.
      </p>
    </div>

    <div class="toggleGroup" *ngIf="!hidden.has('temporalExtent')">
      <h5 i18n="@@chartSettingsTemporalExtentTitle">Zeitlicher Umfang</h5>
      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.temporalExtent.type"
        (change)="data.chartItem.config.temporalExtent.type = $event.value; compileChart()">
        <mat-button-toggle i18n="@@chartSettingsTemporalExtentGlobal" [value]="'global'">Global</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsTemporalExtentLocal" [value]="'local'">Lokal</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsTemporalExtentManual" [value]="'manual'">Manuell</mat-button-toggle>
      </mat-button-toggle-group>

      <div *ngIf="data.chartItem.config.temporalExtent.type === 'manual'">

        <div>
          <mat-form-field style="width: 100%;">
            <mat-label i18n="@@chartSettingsLastVisibleDays">Anzahl der letzen sichtbaren Tage</mat-label>
            <input type="number" matInput [(ngModel)]="data.chartItem.config.temporalExtent.manualLastDays">
            <span matSuffix i18n="@@chartSettingsDays">Tage</span>
          </mat-form-field>

        </div>

        <h4 style="text-align: center;" i18n="@@chartSettingsOr">
          ODER
        </h4>

        <div>
          <mat-form-field appearance="fill">
            <mat-label i18n="@@chartSettingsFrom">Von</mat-label>
            <input matInput [matDatepicker]="pickerFrom"
              [(ngModel)]="data.chartItem.config.temporalExtent.manualExtent[0]">
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
          </mat-form-field>

          <mat-form-field appearance="fill">
            <mat-label i18n="@@chartSettingsTo">Bis</mat-label>
            <input matInput [matDatepicker]="pickerTo"
              [(ngModel)]="data.chartItem.config.temporalExtent.manualExtent[1]">
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
          </mat-form-field>
        </div>

        <div style="margin-bottom: 5px;">
          <button mat-raised-button (click)="compileChart()" i18n="@@chartSettingsUpdatePreview">Vorschau aktualisieren</button>
        </div>

      </div>

      <p class="hint" i18n="@@chartSettingsTemporalExtentHint">Global macht Charts besser vergleichbar. Lokal hebt
        Eigenschaften in den Daten eines Charts besser hervor.</p>
    </div>

    <div class="toggleGroup" *ngIf="!hidden.has('valueExtent')">
      <h5 i18n="@@chartSettingsValueExtentTitle">Werte Umfang</h5>
      <mat-button-toggle-group class="btngrp" name="fontStyle" aria-label="Font Style"
        [value]="data.chartItem.config.valueExtent.type"
        (change)="data.chartItem.config.valueExtent.type = $event.value; compileChart()">
        <mat-button-toggle i18n="@@chartSettingsTemporalExtentGlobal" [value]="'global'">Global</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsTemporalExtentLocal" [value]="'local'">Lokal</mat-button-toggle>
        <mat-button-toggle i18n="@@chartSettingsTemporalExtentManual" [value]="'manual'">Manuell</mat-button-toggle>
      </mat-button-toggle-group>
  
      <div *ngIf="data.chartItem.config.valueExtent.type === 'manual'">
  
        <div>
          <mat-form-field class="example-full-width">
            <mat-label i18n="@@chartSettingsFrom">Von</mat-label>
            <input type="number" matInput [(ngModel)]="data.chartItem.config.valueExtent.manualExtent[0]">
          </mat-form-field>
  
          <mat-form-field class="example-full-width">
            <mat-label i18n="@@chartSettingsTo">Bis</mat-label>
            <input type="number" matInput [(ngModel)]="data.chartItem.config.valueExtent.manualExtent[1]">
          </mat-form-field>
        </div>
  
        <div style="margin-bottom: 5px;">
          <button mat-raised-button (click)="compileChart()" i18n="@@chartSettingsUpdatePreview">Vorschau aktualisieren</button>
        </div>
  
      </div>
  
      <p class="hint" i18n="@@chartSettingsTemporalExtentHint">Global macht Charts besser vergleichbar. Lokal hebt
        Eigenschaften in den Daten eines Charts besser hervor.</p>
    </div>

  </div><!-- end settings -->

</div>


<p class="error" *ngIf="hasErrorState()" i18n="@@chartSettingsHasError">Es gibt einen Fehler. Bitte prüfe deine
  Konfiguration.</p>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" [mat-dialog-close]="data" i18n="@@buttonSave"
    [disabled]="hasErrorState()">Speichern</button>
  <button mat-raised-button color="warn" (click)="onNoClick()" i18n="@@buttonCancel">Abbrechen</button>
</div>
