<table style="width: 100%;" mat-table [dataSource]="dashboards" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->
  
    <!-- Position Column -->
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef> Name </th>
      <td mat-cell *matCellDef="let element"> <a [routerLink]="['/', 'overview', 'dashboard', element.id]"> {{element.title}} </a></td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="upvotes">
      <th mat-header-cell *matHeaderCellDef> Upvotes </th>
      <td mat-cell *matCellDef="let element"> {{element.upvotes}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="visits">
      <th mat-header-cell *matHeaderCellDef i18n="@@dashboardTableVisits"> Aufrufe </th>
      <td mat-cell *matCellDef="let element"> {{element.visits}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="created">
      <th mat-header-cell *matHeaderCellDef i18n="@@dashboardTableCreated"> Erstellt </th>
      <td mat-cell *matCellDef="let element"> {{element.created_at | date: 'long'}} </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>