<span class="hidden-translation" i18n="@@risklayerWarning" #risklayerWarning>Seit dem 08.03.2022 hat Risklayer die Datenerhebung beendet. Wir bedanken uns bei Risklayer für die fantastische Datengrundlage und die unermüdliche Arbeit. Die Daten des RKI stehen weiterhin tagesaktuell zur Verfügung.</span>
<img class="tracking-pxl" src="https://web-analytics.dbvis.de/matomo.php?idsite={{ siteId }}&amp;rec=1" style="border:0" alt="" />
<div id="container">
  <app-map
    id="map"
    [mapOptions]="mapOptions"
    (caseChoroplethLayerChange)="currentCaseChoropleth$.next($event)"
    [mapLocationSettings]="initialMapLocationSettings"
    (mapLocationSettingsChange)="mapLocationSettingsUpdated($event)"
    [flyTo]="flyTo"
  ></app-map>

  <app-infobox
    *ngIf="mapOptions"
    class="infobox"
    [mapOptions]="mapOptions"
    [mapLocationSettings]="currentMapLocationSettings"
    (mapOptionsChange)="mapOptionsUpdated($event)"
    (flyTo)="flyTo = $event"
    [choroplethLayer$]="currentCaseChoropleth$"
  ></app-infobox>

  <app-overlay-brand class="overlay-brand"></app-overlay-brand>
  <app-legend
    *ngIf="mapOptions$"
    class="legend"
    [mapOptions$]="mapOptions$"
    (mapOptionsChange)="mapOptionsUpdated($event, true)"
    [choroplethLayer$]="currentCaseChoropleth$"
  ></app-legend>

  <div class="timeslider">
    <app-timeslider
    [mapOptions]="mapOptions"
    (mapOptionsChange)="mapOptionsUpdated($event)"
    ></app-timeslider>
  </div>
  

</div>
