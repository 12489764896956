
<div class="close-button-wrapper">
  <button class="close-button" (click)="close()" mat-icon-button aria-label="Close Dialog" matTooltip="Close Window">
    <mat-icon>close</mat-icon>
  </button>
</div>

<span class="hidden-placeholder" i18n='@@helpProjektLabel1' #label1>{isSmallScreen, select, true {Projekt} false {Das Projekt}}</span>
<span class="hidden-placeholder" i18n='@@helpProjektLabel2' #label2>{isSmallScreen, select, true {Betten} false {Darstellung der Bettenkapazitäten}}</span>
<span class="hidden-placeholder" i18n='@@helpProjektLabel3' #label3>{isSmallScreen, select, true {Fälle} false {Darstellung der Fallzahlen}}</span>
<span class="hidden-placeholder" i18n='@@helpProjektLabel4' #label4>Live Ansicht</span>

<div mat-dialog-content>
  <mat-tab-group #tabGroup >
    <mat-tab [label]="label1.textContent">
      <app-help-dialog-theproject></app-help-dialog-theproject>
      <div fxLayout="row" fxLayout.xs="column">
        <button mat-button mat-raised-button color="primary"  (click)="tabGroup.selectedIndex=1"  i18n="help| help@@button11">{{ (isSmallScreen$ | async).matches ? 'Weiter' : 'Zur Beschreibung der Kapazitätsdarstellung' }}</button>
        <button mat-button mat-stroked-button (click)="close()"   i18n="help| help@@button12">Fenster schließen</button>
      </div>
    </mat-tab>
    <mat-tab [label]="label2.textContent">
      <app-help-dialog-beds></app-help-dialog-beds>
      <div fxLayout="row" fxLayout.xs="column">
        <button mat-button mat-stroked-button (click)="tabGroup.selectedIndex=0"  i18n="help| help@@button21">Zurück</button>
        <button mat-button mat-raised-button color="primary" (click)="tabGroup.selectedIndex=2"  i18n="help| help@@button22">{{ (isSmallScreen$ | async).matches ? 'Weiter' : 'Weiter zur Darstellung der Fallzahlen' }}</button>
        <button mat-button mat-stroked-button (click)="close()"  i18n="help| help@@button23">Fenster schließen</button>
      </div>
    </mat-tab>
    <mat-tab [label]="label3.textContent">
      <app-help-dialog-cases></app-help-dialog-cases>
      <div fxLayout="row" fxLayout.xs="column"> 
        <button mat-button mat-stroked-button  (click)="tabGroup.selectedIndex=1" i18n="help| help@@button31">Zurück</button>
        <button mat-button mat-raised-button color="primary" (click)="tabGroup.selectedIndex=3"  i18n="help| help@@buttonLiveView">{{ (isSmallScreen$ | async).matches ? 'Weiter' : 'Weiter zur Live Ansicht' }}</button>
        <button mat-button mat-stroked-button (click)="close()"  i18n="help| help@@button32">Fenster schließen</button> 
      </div>
    </mat-tab>
    <mat-tab [label]="label4.textContent">
      <app-help-dialog-live-view></app-help-dialog-live-view>
      <div fxLayout="row" fxLayout.xs="column"> 
        <button mat-button mat-stroked-button  (click)="tabGroup.selectedIndex=2" i18n="help| help@@button31">Zurück</button>
        <button mat-button mat-raised-button color="primary" (click)="close()"  i18n="help| help@@button32">Fenster schließen</button> 
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
