
<div class="legend-wrapper">
  <div class="legend-inner bedlegend" *ngIf="(mo$ | async)?.bedBackgroundOptions.enabled === false && choroplethLayer$ | async">
    <h5 
    class="title"
    (click)="legendCasesExtended = !legendCasesExtended" 
    i18n-matTooltip="@@legendToggleTooltip" 
    matTooltip="Klicken um Legende ein-/auszuklappen"
    matTooltipPosition="above"
    >{{ titleCases$ | async }}</h5>

    <h5 class="title" *ngIf="numCounties$ | async as num" i18n="@@legendSelectedRegions">
      {{num.sel}}/{{num.total}} {num.aggLevel, select, landkreise {Kreise} regierungsbezirke {Regierungsbezirke} bundeslaender {Bundesländer} laender {Länder} other {Regionen}}
    </h5>

      <div *ngIf="legendCasesExtended">
      <table>
  
        <tr *ngFor="let b of caseBins$ | async" [ngClass]="{'selected': isBinSelected(b), 'hovered': isBinHovered(b)}" (mouseenter)="hoverBin(b)" (mouseout)="hoverBin()" (click)="selectBin(b)" class="bin">
  
          <td><div class="rect" [ngStyle]="{ 'background-color': b.color }"></div></td>
  
          <td class="lower">
            {{ b.minStr }}
          </td>
  
          <td class="dash" *ngIf="b.min !== b.max">–</td>
  
          <td class="upper" *ngIf="b.min !== b.max">
            {{ b.maxStr }}
          </td>
        </tr>
  
        <tr *ngIf="(mo$ | async)?.covidNumberCaseOptions.showOnlyAvailableCounties">
          <td><div class="rect" [ngStyle]="{ 'background-color': caseColormap.getUnavailableColor() }"></div></td>
  
          <td colspan="3" class="lower" i18n="@@legendNotAvailable">
            nicht verfügbar
          </td>
        </tr>
      </table>
  
      <p i18n="@@clearSelection" class="clear-selection" *ngIf="currentOptions?.covidNumberCaseOptions?._binSelection?.length > 0" (click)="clearSelection()">Selektion aufheben</p>
  
      </div>
          
      <div *ngIf="!legendCasesExtended">
        <div class="row" *ngIf="caseBins$ | async; let bins">
          <div class="text" [innerHtml]="bins[0]?.minStr"></div>
          <div *ngFor="let b of bins"  class="rect" [ngStyle]="{ 'background-color': b.color }" matTooltip="{{ b.minStr }} – {{ b.maxStr }}"></div>
          <div class="text" [innerHtml]="bins[bins.length - 1]?.maxStr"></div>
        </div>
      </div>
  
      <div style="margin-top: 5px;">
        <app-data-source [source]="(mo$ | async)?.covidNumberCaseOptions.dataSource"></app-data-source>
      </div>
  </div>
  
  
  <div class="legend-inner bedlegend" *ngIf="(mo$ | async)?.bedBackgroundOptions.enabled === true || (mo$ | async)?.bedGlyphOptions.enabled === true" >
    <h5
    class="title" 
    (click)="legendBedsExtended = !legendBedsExtended" 
    i18n-matTooltip="@@legendToggleTooltip" 
    matTooltip="Klicken um Legende ein-/auszuklappen"
    matTooltipPosition="above"
    >{{ titleBeds$ | async }}</h5>
  
    <div *ngIf="legendBedsExtended">
    <table>
      <tr *ngFor="let b of bedStatusColors">
        <td><div class="rect" [ngStyle]="{ 'background-color': getBedColor(b) }"></div></td>
  
        <td class="text">{{ b | translate }}</td>
      </tr>
    </table>
    </div>
    
    <div *ngIf="!legendBedsExtended">
      <div class="row">
        <div class="beside" *ngFor="let b of bedStatusColors;" matTooltip="{{ b | translate }}">
  
          <div class="rect" [ngStyle]="{ 'background-color': getBedColor(b) }"></div>
  
          <div class="text">{{ bedStatusIcons[b] | translate }}</div>
  
        </div>
      </div>
    </div>

    <div style="margin-top: 5px;">
      <app-data-source source="divi"></app-data-source>
    </div>
  </div>
</div>
