<mat-card class="card">
    <div class="new-dashboard-container">
        <app-region-selector class="region-selector" [(selectedRegions)]="selectedRegions" maxRegions="1"></app-region-selector>
        <div class="btn-container">
            <button [disabled]="selectedRegions.length !== 1" mat-raised-button color="primary" 
            [routerLink]="['/', 'overview', 'dashboard', selectedRegions[0]?.id]" 
            i18n="@@dashboardsOverviewNew">Neues Dashboard für {{ selectedRegions[0]?.name }}</button>
        </div>

        <div class="btn-container">
            <a i18n="@@dashboardsOverviewVideoButton" href="https://www.youtube.com/watch?v=I1ORQd9W9Ok" target="_blank" mat-raised-button>Video: Eigenes Dashboard erstellen <mat-icon>launch</mat-icon></a>
        </div>
    </div>
    
    <h1 i18n="@@dashboardsOverviewPopular">Beliebteste Dashboards</h1>

    <app-dashboards-table [dashboards]="mostUpvoted$"></app-dashboards-table>

    <h1 i18n="@@dashboardsOverviewMostVisited">Populärste Dashboards</h1>

    <app-dashboards-table [dashboards]="mostVisited$"></app-dashboards-table>

    <h1 i18n="@@dashboardsOverviewNewest">Neueste Dashboards</h1>

    <app-dashboards-table [dashboards]="newest$"></app-dashboards-table>

</mat-card>

<app-footer></app-footer>
