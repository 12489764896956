<div style="padding:10px;" *ngIf="showButtons">
    <a mat-raised-button color="primary" routerLink="/overview/map/ebrake" i18n="@@ebrakeOverviewMapView">Kartenansicht</a>&nbsp;

    <button mat-raised-button (click)="openShareDialog()" i18n="@@ebrakeOverviewShareDialog">Visualisierung in Webseite einbinden</button>
</div>

<div style="padding:10px;" *ngIf="showRegions">
    <app-region-selector [selectedRegions]="activeRegions" (selectedRegionsChange)="updateRegions($event)" [maxRegions]="null"></app-region-selector>
</div>

<div>
    <app-temporal-overview-chart [data]="data"></app-temporal-overview-chart>
</div>

<app-footer *ngIf="showFooter"></app-footer>