<mat-toolbar color="primary" class="footer">
  <div>
    <a href="https://coronavis.dbvis.de">
      <img class="coronavislogo" src="/assets/coronavislogo300.png" alt="CoronaVis Logo">
    </a>
  </div>
  <div>
    <a href="http://vis.uni.kn">
      <img class="dbvislogo" src="/assets/dbvis.png" alt="DBVIS Logo">
    </a>
  </div>



  <span class="example-spacer"></span>

  <a class="item" routerLink="/overview/imprint" i18n="@@imprint">Impressum</a> |
  <a class="item" routerLink="/overview/gdpr" i18n="@@gdpr">Datenschutzerklärung</a>
</mat-toolbar>
