<span class="hidden-translation" #i18nPictureCopiedToClipboard i18n="@@pictureCopiedToClipboard">Screenshot in Zwischenablage kopiert</span>
<span class="hidden-translation" #i18nPictureCopiedToClipboardButton i18n="@@pictureCopiedToClipboardButton">Stattdessen herunterladen</span>
<div class="outer" *ngIf="dashboard">
    <mat-toolbar color="primary">
      <button (click)="openVideo()" mat-icon-button matTooltip="Video">
        <mat-icon>help</mat-icon>
      </button>

      <button mat-icon-button matTooltip="Copy URL" (click)="copyUrl()">
        <mat-icon>add_link</mat-icon>
      </button>
      <button mat-icon-button matTooltip="Save as new dashboard" (click)="save()">
        <mat-icon>save</mat-icon>
      </button> | 
      <button mat-icon-button matTooltip="Edit title" (click)="openEditTitleDialog()">
        <mat-icon>edit</mat-icon>
      </button>
      <h1>{{ dashboard.title }}</h1>

      

      <span class="example-spacer"></span>

      <button mat-icon-button matTooltip="Upvote this dashboard" (click)="upvote()" [disabled]="dashboard.id.length < 6">
        <mat-icon>thumb_up</mat-icon>
      </button> {{ dashboard.upvotes }} | 
      &nbsp;<mat-icon matTooltip="Number of views">visibility</mat-icon>&nbsp; {{ dashboard.visits }}
    </mat-toolbar>
    <mat-card>

      <div class="main">

        <div *ngIf="historySpec">

          <mat-accordion>
            <mat-expansion-panel [expanded]="expandVersionHistory">
              <mat-expansion-panel-header>
                <mat-panel-title i18n="@@dashboardVersionHistory">
                  Versions Verlauf
                </mat-panel-title>
              </mat-expansion-panel-header>
              <app-vega [spec]="historySpec" (vegaClick)="navigateToDashboard($event)"></app-vega>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
          



        <div class="root timelines draggable-list" cdkDropList (cdkDropListDropped)="drop($event)">


          <div class="draggable-box timeline row" *ngIf="pleaseWait">           
            
            <div class="icons"> 
              <button mat-icon-button aria-label="settings" class="icon filter" disabled="disabled" matTooltip="Filter Settings">
                  <mat-icon>settings</mat-icon>
              </button> 
    
              <button mat-icon-button aria-label="delete" class="icon filter" disabled="disabled" matTooltip="Remove">
                  <mat-icon>delete</mat-icon>
              </button>
            </div>
    

            <div class="please-wait" #pleaseWait style="width: 100%">
              <mat-spinner diameter="40" strokeWidth="2" style="margin: 40px 80px;"></mat-spinner>
              <h3 i18n="@@pleaseWaitLoading">Bitte warten... lädt</h3>
            </div>
          </div>

            <div class="draggable-box timeline row" *ngFor="let timelineItem of dashboard.items; index as idx" cdkDrag>           
              
              <div class="icons">
                <mat-icon class="icon draw" aria-hidden="false" aria-label="drag me" cdkDragHandle>drag_indicator</mat-icon>

                <button *ngIf="timelineItem.type === 'markdown'" mat-icon-button aria-label="settings" class="icon filter" (click)="timelineItem._text = timelineItem.text; timelineItem._editMode = true" matTooltip="Edit Text" >
                    <mat-icon>edit</mat-icon>
                </button>
      
                <button *ngIf="timelineItem.type !== 'markdown'" mat-icon-button aria-label="settings" class="icon filter" (click)="openSettings(timelineItem, idx)" matTooltip="Chart Settings">
                    <mat-icon>settings</mat-icon>
                </button>

                <button mat-icon-button aria-label="duplicate" class="icon filter" (click)="duplicate(timelineItem, idx)" matTooltip="Duplicate chart">
                  <mat-icon>content_copy</mat-icon>
                </button>

                <button *ngIf="timelineItem.type !== 'markdown'" mat-icon-button aria-label="download" class="icon filter" (click)="screenshot(idx)" matTooltip="Screenshot erstellen" i18n-matTooltip="@@takeScreenshot">
                  <mat-icon>photo_camera</mat-icon>
                </button>

                <button *ngIf="timelineItem.type === 'multiline' || timelineItem.type === 'pixel' || timelineItem.type === 'stackedareaicu'" mat-icon-button aria-label="download" class="icon filter" (click)="downloadData(timelineItem)" matTooltip="Download data as CSV">
                  <mat-icon>download</mat-icon>
                </button>
    
                <button *ngIf="!timelineItem._reallyDelete" mat-icon-button aria-label="delete" class="icon filter" (click)="timelineItem._reallyDelete = true" matTooltip="Remove">
                    <mat-icon>delete</mat-icon>
                </button>

                <div *ngIf="timelineItem._reallyDelete">
                  <button mat-icon-button aria-label="delete" class="icon filter" (click)="remove(timelineItem)" matTooltip="Yes, remove">
                    <mat-icon>check</mat-icon>
                  </button>

                  <button mat-icon-button aria-label="delete" class="icon filter" (click)="timelineItem._reallyDelete = false" matTooltip="No, cancel">
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>
      
              <div *ngIf="timelineItem.type === 'markdown'" class="full-width" #dashboardChart>
                <div *ngIf="timelineItem._editMode">
                  <mat-toolbar i18n="@@dashboardVorschau">Vorschau</mat-toolbar>
                  <markdown [ngStyle]="{'display': 'block', 'background-color': 'lightgrey'}" [data]="timelineItem._text" emoji></markdown>

                  <p class="markdown-hint" i18n="@@dashboardMarkdownExplanation">Formatiere deinen Text mit Markdown. Hier findest du einen <a target="_blank" href="https://commonmark.org/help/">Cheatsheet <mat-icon inline="true">launch</mat-icon></a></p>
  
                  <mat-form-field style="margin-top: 10px;" class="full-width">
                    <mat-label i18n="@@dashboardEditText">Text bearbeiten</mat-label>
                    <textarea matInput [(ngModel)]="timelineItem._text" cdkTextareaAutosize></textarea>
                  </mat-form-field>

                  <button mat-raised-button color="primary" (click)="timelineItem.text = timelineItem._text; timelineItem._editMode = false" i18n="@@buttonSave">Speichern</button> 
                  <button style="margin-left: 10px;" mat-raised-button color="warn" (click)="timelineItem._editMode = false" i18n="@@buttonCancel">Abbrechen</button>
                </div>

                <markdown *ngIf="!timelineItem._editMode" [data]="timelineItem.text" emoji></markdown>
              </div>
              
              <div *ngIf="timelineItem.type === 'separator'" class="separator">
                <hr>
              </div>

              <div *ngIf="timelineItem.type === 'pixel'" style="width: 100%;" #dashboardChart>
                <app-pixel-chart [dataAndOptions]="timelineItem._compiled"></app-pixel-chart>
              </div>

              <div *ngIf="timelineItem.type === 'multiline'" style="width: 100%" #dashboardChart>
                <app-multi-line-chart [dataAndOptions]="timelineItem._compiled"></app-multi-line-chart>
              </div>

              <div *ngIf="timelineItem.type === 'table'" style="display: inline-block;" #dashboardChart>
                <app-table-overview [tableData]="timelineItem._compiled"></app-table-overview>
              </div>

              <div *ngIf="timelineItem.type === 'stackedareaicu'" style="width: 100%" #dashboardChart>
                <app-stacked-area-icu-chart [dataAndOptions]="timelineItem._compiled"></app-stacked-area-icu-chart>
              </div>
            </div>
      
            <div class="timeline row button-row">
              <div>
                <button mat-fab color="primary" [matMenuTriggerFor]="menu">
                  <mat-icon>add</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="add('markdown')" i18n="@@dashboardAddTextBlock">Neuen Textblock hinzufügen</button>
                  <button mat-menu-item (click)="add('multiline')" i18n="@@dasboardAddChart">Neuen Chart hinzufügen</button>
                </mat-menu>
              </div>
            </div>
          </div>
      </div>
    </mat-card>
  </div>

<app-footer></app-footer>
