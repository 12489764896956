<span class="hidden" #i18nAltersgruppe i18n="@@caseDodYAxisAltersgruppe">Altersgruppe</span>
<span class="hidden" #i18nDatum i18n="@@caseDodXAxisDatum">Datum</span>
<span class="hidden" #i18nWoche i18n="@@caseDodXAxisWoche">Woche</span>
<span class="hidden" #i18nVal i18n="@@caseDodZAxisVal">7-Tage-Inzidenz</span>

<h4 class="title">
    {{_dataAndOptions?.chartOptions?.title}}
</h4>


<app-vega *ngIf="spec" [spec]="spec"></app-vega>

<div *ngIf="!spec" class="loading">
    <mat-spinner diameter="40" strokeWidth="2" style="margin: 40px 80px;"></mat-spinner>
</div>


<div class="datasources" *ngIf="_dataAndOptions">
    <app-data-source [source]="_dataAndOptions.config.dataSource" [hideHint]="true"></app-data-source>
</div>