<!--h1 mat-dialog-title></h1
<div class="close-button-wrapper">
  <button class="close-button" (click)="close()" mat-icon-button aria-label="Close Dialog">
    <mat-icon>close</mat-icon>
  </button>
</div>-->

<div mat-dialog-title>
  <div class="title-row">
    <div class="title-row-title">
      <h3 class="name">{{ data.data.name }}</h3>

      <p class="numHospitals" *ngIf="!isSingleHospital">({{ totalNumberOfHospitals }} <span i18n="tooltip | hospitals@reported">gemeldete Krankenhäuser</span>)</p>
    </div>
    <div>
      <button (click)="close()" mat-icon-button aria-label="Close Dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div> 
  </div>
</div>
<mat-divider></mat-divider>

<div class="main" mat-dialog-content>
  <app-hospital-info [data]="data.data" [options]="data.options" mode="dialog"></app-hospital-info>
</div>
<!-- <div style="float: right;" mat-dialog-actions>
  <button class="mat-raised-button"(click)="close()" i18n="button@@close">Schließen</button>
</div> -->

