<div class="flex-container">
  <div>
    <a href="http://cgmi.uni.kn">
      <img class="cgmilogo" src="/assets/cgmi.png" alt="CGMI Link">
    </a>
  </div>


  <div>
    <a href="http://vis.uni.kn">
      <img class="dbvislogo" src="/assets/dbvis.png" alt="DBVIS Logo">
    </a>
  </div>

  <div>
    <a href="https://wirvsvirus.org/solution-enabler/">
      <img class="solutionenablerlogo" src="/assets/solution-enabler.png" alt="WirVsVirus Solution Enabler Logo">
    </a>
  </div>
</div>
