import { Component } from '@angular/core';

@Component({
  selector: 'app-overview-introduction',
  templateUrl: './overview-introduction.component.html',
  styleUrls: ['./overview-introduction.component.less']
})
export class OverviewIntroductionComponent {

}
