<div mat-dialog-title>
  <div class="title-row">
    <div class="title-row-title">
      <h3 class="name">{{ data.data.description ? data.data.description + ' ' : '' }}{{ data.data.name }}</h3>

      <p class="numHospitals" *ngIf="this.currentTimedStatus">{{ this.currentTimedStatus.population | number }} <span i18n="case-tooltip|cases@@residents">Einwohner</span></p>
    </div>
    <div>
      <button (click)="close()" mat-icon-button aria-label="Close Dialog">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
</div>

<div mat-dialog-content>
    <app-case-info [data]="data.data" [options]="data.options"></app-case-info>
</div>