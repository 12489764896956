<div class="main">

  <div class="title-row">
    <div class="title-row-title">
      <h3 class="name">{{ tooltipData.name }}</h3>

      <p class="numHospitals" *ngIf="!isSingleHospital">({{ totalNumberOfHospitals }} <span i18n="tooltip | hospitals@reported">gemeldete Krankenhäuser</span>)</p>
    </div>
</div>

<mat-divider></mat-divider>

  <app-hospital-info [data]="tooltipData" [options]="options" mode="tooltip"></app-hospital-info>
</div>
