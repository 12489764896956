<form class="example-form">
  <mat-form-field class="example-full-width">
    <mat-icon matPrefix>search</mat-icon>
    <input type="search" placeholder="Search" aria-label="Search" matInput [formControl]="myControl"
      [matAutocomplete]="auto">
    <button mat-button *ngIf="myControl.value" matSuffix mat-icon-button aria-label="Clear"
      (click)="myControl.setValue('')">
      <mat-icon>close</mat-icon>
    </button>
    <mat-autocomplete class="autocomplete" autoActiveFirstOption #auto="matAutocomplete"
      (optionSelected)="selected($event)">
      <mat-option *ngFor="let h of filteredOptions | async" [value]="h.name + ':' + h.addition" class="option">
        {{h.name}}
        <small *ngIf="h.addition">{{h.addition}}</small>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
