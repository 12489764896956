<h1 id="beschreibung-der-darstellung-der-bettenkapazitäten"   i18n="help| help@@title2">Beschreibung der Darstellung der Bettenkapazitäten</h1>
<div fxLayout="row" fxLayout.xs="column" class="visdescription">
    <div fxFlex class="helpimg">
      <img flex="66" class="helpimg" style="max-width: 550px;" src="/assets/help-dialog-beds-de.png" i18n-src="@@help-dialog-beds-image" alt="Explanation Figure">
    </div>
    <div fxFlex class="helptxt">
        <p i18n="help| help@@helpdialog-beds-1">So wählen Sie die Darstellung der Bettenauslastung in den Krankenhäusern:</p>

        <p i18n="help| help@@helpdialog-beds-2"><u><b>Wo?</b> (A) Wählen Sie eine Aggregationsstufe für die Darstellung aus:</u></p>
        <ol>
          <li i18n="help| help@@helpdialog-beds-2.1">Bundesländer (Länder), </li>
          <li i18n="help| help@@helpdialog-beds-2.2">Regierungsbezirke (Bezirke), </li>
          <li i18n="help| help@@helpdialog-beds-2.3">Landkreise (Kreise), </li>
          <li i18n="help| help@@helpdialog-beds-2.4">Alle Krankenhäuser (Keine) </li>
        </ol>

        <br>

        <p i18n="help| help@@helpdialog-beds-3"><u><b>Was?</b> (B) Wählen Sie die Art der Behandlungsmöglichkeit:</u> </p>
        <p i18n="help| help@@helpdialog-beds-3.0">Wählen Sie zwischen der Darstellung von drei Behandlungsmöglichkeiten.</p>
        <ol>
          <li i18n="help| help@@helpdialog-beds-3.1">Nichtinvasive Beatmung: ICU low care</li>
          <li i18n="help| help@@helpdialog-beds-3.2">Invasive Beatmung: ICU high care</li>
          <li i18n="help| help@@helpdialog-beds-3.3">Beatmung durch extrakorporale Membranoxygenierung: ECMO</li>
        </ol>

        <p i18n="help| help@@helpdialog-beds-3.4">Sie können nur eine Behandlungsmöglichkeit für die Darstellung auswählen, diese kombinieren oder aber sich alle drei Behandlungsmöglichkeiten gleichzeitig anzeigen lassen. Ihre Auswahl bestimmt die Zusammenstellung der Glyphen auf der Karte und, falls aktiv (D) die Hintergrundeinfärbung.</p>

        <br>

        <p i18n="help| help@@helpdialog-beds-4"><u><b>Wieviel?</b> (C) Darstellung der Bettenauslastung:</u> </p>
        <ul>
          <li i18n="help| help@@helpdialog-beds-4.1">Grün: Freie Betten - keine Einschränkungen</li>
          <li i18n="help| help@@helpdialog-beds-4.2">Gelb: Betten werden knapp</li>
          <li i18n="help| help@@helpdialog-beds-4.3">Rot: Betten sind komplett ausgelastet</li>
        </ul>

        <p i18n="help| help@@helpdialog-beds-5">
            Wenn Sie mit der Maus über die Karte fahren, werden Ihnen für den jeweiligen gewählten Ort die entsprechenden Zahlen angezeigt (E, F).
        </p>
    </div>
  </div>