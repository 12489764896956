<h1 i18n="@@helpLiveView">
  Live Ansicht
</h1>
<div fxLayout="row" fxLayout.xs="column" class="visdescription">
  <div fxFlex class="helptxt">
    <p i18n="@@helpLiveViewIntro">
      Die Live Ansicht wird durch die Firma <a href="http://risklayer.com/">Risklayer GmbH</a> und das
      <a href="https://www.cedim.kit.edu/"
        matTooltip="Center for Disaster Management and Risk Reduction Technology">CEDIM</a> am
      <a href="https://www.kit.edu/" matTooltip="Karlsruhe Institute of Technology">KIT</a> und durch die
      <a href="https://docs.google.com/spreadsheets/d/1wg-s4_Lz2Stil6spQEYFdZaBEp8nWW26gVyfHqvcl8s/htmlview#">Risklayer-CEDIM-Tagesspiegel
        SARS-CoV-2 Crowdsourcing Contributors</a> ermöglicht.
    </p>

    <p i18n="@@helpLiveViewSheet">
      Mit der Hilfe von freiwilligen Personen wird ein <a
        href="https://docs.google.com/spreadsheets/d/1wg-s4_Lz2Stil6spQEYFdZaBEp8nWW26gVyfHqvcl8s/htmlview#">öffentlich
        zugänglicher Google Sheet</a>
      dauerhaft mit den aktuellsten Zahlen zur Covid-19 Pandemie betrieben (genannt crowdsourcing).
      Die Daten stammen in der Regel von den Veröffentlichungen der Landkreise bzw. der Gesundheitsämter.
      Im Gegensatz zu den offiziellen Zahlen des Robert Koch Instituts sind diese Daten aktueller, können aber
      Ungenauigkeiten enthalten.
      Die Daten des Robert Koch Instituts sind normalerweise zum Stand der Veröffentlichung ca. 48 Stunden alt.
      Die Datengrundlage des Google Sheets ist tagesaktuell und wird von uns halb-stündlich aktualisiert.
      Über den Tag werden somit die Daten der 401 Landkreise aufgenommen.
      Wenn noch keine Daten vorliegen werden die Daten des Vortags verwendet.
    </p>

    <p i18n="@@helpLiveViewPrognosis">
      In dem Menu wird eine Hochrechnung für die neu erwarteten gemeldeten Covid-19 Fälle für den aktuellen Tag dargestellt.
      Die Hochrechnung basiert auf den aktuellen Zahlen der Landkreise die bereits Daten veröffentlicht haben bzw. auf deren vorläufigen Daten.
    </p>

    <p i18n="@@helpLiveViewAutoload">
      Die Live Ansicht wird automatisch alle 30 Minuten aktualisiert.
      Von 12 Uhr bis 6 Uhr morgens findet keine Aktualisierung statt da in diesem Zeitraum der Google Sheet für den nächsten Tag vorbereitet wird.
    </p>


  </div>
</div>
