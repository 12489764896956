
<!-- <div class="cur">
    {{ currentTimeDate | date:'shortDate' }}
</div> -->
<div class="slider-container">
    <nouislider 
    *ngIf="nouiConfig"
    class="nouitimeslider" 
    [config]="nouiConfig"
    [(ngModel)]="currentTime" 
    (ngModelChange)="nouiSliderChanging($event)" 
    (change)="nouiSliderChanged($event)"
    ></nouislider>
</div>

<!-- <div class="button-container">
    <button *ngIf="nouiConfig" class="play" mat-mini-fab aria-label="Play animation" (click)="modePlaying$.next(!modePlaying$.value)">
        <mat-icon *ngIf="modePlaying$.value === false">play_arrow</mat-icon>
        <mat-icon *ngIf="modePlaying$.value === true">pause</mat-icon>
    </button>
</div> -->



<!-- <mat-slider
        class="slider"
        [max]="timeExtent[1]"
        [min]="timeExtent[0]"
        [step]="24 * 60 * 60"
        [tickInterval]="numTicks"
        [(ngModel)]="currentTime"
        (input)="matSliderChanging($event)"
        (change)="matSliderChanged($event)"
>
    </mat-slider> -->