<div class="outer">
  <mat-card>

    <strong>Datenschutzinformation</strong><br><br>Stand: 30.10.2020<br><br><br><strong>Name und Anschrift des
      Datenschutzbeauftragten</strong><br>Der Datenschutzbeauftragte des Verantwortlichen ist erreichbar
    unter:<br>E-Mail:
    dsgvo&#64;dbvis.inf.uni-konstanz.de<br><br><strong>Name und Anschrift des Verantwortlichen</strong><br>Der
    Verantwortliche
    im Sinne der Datenschutz-Grundverordnung und anderer nationaler Datenschutzgesetze der Mitgliedstaaten sowie
    sonstiger
    datenschutzrechtlicher Bestimmungen ist die:<br><br>AG Keim<br>Universit&#228;t Konstanz<br>vertreten durch den
    Lehrstuhlinhaber, Prof. Dr. Daniel A. Keim<br>Universit&#228;tsstra&#223;e 10<br>78464 Konstanz<br>Tel. +49 7531
    88-3161<br>E-Mail: keim&#64;uni-konstanz.de<br><br>Inhaltliche Verantwortung:<br>Matthew Sharinghousen<br>E-Mail:
    dsgvo&#64;dbvis.inf.uni-konstanz.de<br>Website: coronavis.dbvis.de<br><br><strong>E-Mail-Informationsservice auf
      coronavis.dbvis.de</strong><br><br>Das Tool CoronaVis bietet einen E-Mail-Informationsservice, der Nutzern
    Berichte
    &#252;ber COVID-19-Statistiken in ausgew&#228;hlten Gebieten in regelm&#228;&#223;igen Abst&#228;nden zuschickt. Zur
    Umsetzung dieses Services und dem Versand der damit verbundenen E-Mail-Benachrichtigungen &#252;ber die
    COVID-19-Statistiken und Servicebezogene Ank&#252;ndigungen speichern wir folgende Daten:&nbsp;<br><br>-
    E-Mail-Adresse<br>- Regionen, &#252;ber die Statistiken an die E-Mail-Adresse versendet werden<br>- Die
    Spracheinstellung des Nutzers<br><br>Rechtsgrundlage f&#252;r die Speicherung der Logfiles ist Art. 6 Abs. 1 lit. e)
    i.V.m. Abs. 3 DSGVO i.V.m. &#167; 4 Landesdatenschutzgesetz BW (im Folgenden: LDSG) in der ab dem 6. Juni 2018
    geltenden
    Fassung.<br><br>Die erhobenen Daten werden ausschlie&#223;lich zur Erbringung der oben genannten Serviceleistungen
    gespeichert. Bei K&#252;ndinung der Benachrichtigungen werden alle genannten Daten umgehend
    gel&#246;scht.<br><br><br><strong>Bereitstellung der Webseite und Erstellung von
      Logfiles</strong><br><br><strong>Beschreibung und Umfang der Datenverarbeitung</strong><br>
    <ul class="bullet">
      <li>
        <ul class="bullet">
          <li>Bei jedem Zugriff eines Nutzers oder einer Nutzerin auf eine Seite aus dem Angebot der Website
            coronavis.dbvis.de und bei jedem Abruf einer Datei oder sonstigen Ressource werden folgende Daten &#252;ber
            diesen Vorgang in einer Protokolldatei gespeichert:</li>
          <li>Datum und Uhrzeit der Anfrage</li>
          <li>Adresse und Datenmenge ( in Bytes ) der angefragten und&#x2F;oder abgerufenen Ressource</li>
          <li>IP-Adresse der Besucherin&#x2F; des Besuchers</li>
          <li>Anfragemethode ( z.B. GET-Methode zur Anforderung einer Webseite vom Server )</li>
          <li>Antwort des Servers (HTTP Statuscode, z. B. &#8222;Datei &#252;bertragen.&#8220; oder &#8222;Datei nicht
            gefunden&#8220; etc.</li>
          <li>Website, von der aus der Zugriff erfolgte (zuvor besuchte Website, so genannter &#8222;Referrer&#8220;)
            und
            verwendeter Suchmaschinen-Suchbegriff, sofern &#252;bermittelt</li>
          <li>Erkennungsdaten des verwendeten Browser- und Betriebssystems ( + Version ), sofern &#252;bermittelt</li>
          <li>Geotargeting ( Zuordnung von IP-Adresse zu deren geografischer Herkunft )
        </ul>
      </li>
    </ul><br><strong>Rechtsgrundlage&nbsp;</strong><br>Rechtsgrundlage f&#252;r die Speicherung der Logfiles ist Art. 6
    Abs.
    1 lit. e) i.V.m. Abs. 3 DSGVO i.V.m. &#167; 4 Landesdatenschutzgesetz BW (im Folgenden: LDSG) in der ab dem 6. Juni
    2018
    geltenden Fassung.<br><br><strong>Zweck der Datenverarbeitung</strong><br>Die protokollierten Daten werden
    verwendet, um
    St&#246;rungen oder Fehler an den Systemen, die f&#252;r das Web-Angebot der Universit&#228;t Konstanz erforderlich
    sind, zu erkennen, einzugrenzen oder zu beseitigen. Davon umfasst sind auch St&#246;rungen, die zu einer
    Einschr&#228;nkung der Verf&#252;gbarkeit der Informations- und Kommunikationsdienste oder zu einem unerlaubten
    Zugriff
    auf die Systeme f&#252;hren k&#246;nnen.<br><br><strong>Dauer der Speicherung</strong><br>Die letzten 2 Bytes der
    IP-Adresse werden nach maximal 5 Tagen gel&#246;scht, so dass eine Zuordnung der weiteren erhobenen Daten zu einer
    Person dann nicht mehr m&#246;glich ist. Die anonymisierten Daten werden f&#252;r statistische Zwecke verwendet, um
    die
    Gestaltung der Website kontinuierlich zu optimieren.<br><br><strong>Verwendung von Cookies</strong><br>Beim Besuch
    einer
    Seite wird vom Browser ein so genanntes Sitzungs-Cookie auf dem jeweiligen Endger&#228;t gespeichert. Dieses wird
    gel&#246;scht, sobald der Browser geschlossen wird. Die Sitzungs-Cookies enthalten keine personenbezogenen Daten, so
    dass anhand der Sitzungs-ID kein Bezug auf die Person des Nutzers oder der Nutzerin hergestellt werden kann oder
    beabsichtigt ist. Die Speicherung dieser Cookies kann durch entsprechende Einstellungen im Browser ausgeschaltet
    werden.
    Die Nutzung der Seite wird dadurch nicht eingeschr&#228;nkt, lediglich die Antwortzeit des Servers kann sich
    geringf&#252;gig verschlechtern.<br><br><strong>Webanalyse</strong><br>Diese Webseite verwendet Matomo, eine Open
    Source, selbstgehostete Software um anonyme Nutzungsdaten f&#252;r diese Webseite zu sammeln.<br>Die Daten zum
    Verhalten
    der Besucher werden gesammelt um eventuelle Probleme wie nicht gefundene Seiten, Suchmaschinenprobleme oder
    unbeliebte
    Seiten herauszufinden. Sobald die Daten (Anzahl der Besucher die Fehlerseiten oder nur eine Seite sehen, usw.)
    verarbeitet werden, erzeugt Matomo Berichte f&#252;r die Webseitenbetreiber, damit diese darauf reagieren
    k&#246;nnen.
    (Layoutver&#228;nderungen, neue Inhalte, usw.)<br>Matomo verarbeitet die folgenden Daten:<br>
    <ul class="bullet">
      <li>Cookies</li>
      <li>Anonymisierte IP-Adressen indem die letzten 2 bytes entfernt werden (also 192.168.0.0 anstatt 192.168.101.15)
      </li>
      <li>Pseudoanonymisierter Standort (basierend auf der anonymisierten IP-Adresse</li>
      <li>Datum und Uhrzeit</li>
      <li>Titel der aufgerufenen Seite</li>
      <li>URL der aufgerufenen Seite</li>
      <li>Bildschirmaufl&#246;sung</li>
      <li>Lokale Zeit</li>
      <li>Dauer des Seitenaufbaus</li>
      <li>Land, Region, Stadt (mit niedriger Genauigkeit aufgrund von IP-Adresse)</li>
      <li>Hauptsprache des Browsers</li>
      <li>User Agent des Browsers
    </ul><br>
    
    <strong>Sentry</strong><br>
    Wir verwenden den Dienst Sentry (Sentry, 1501 Mariposa St #408, San Francisco, CA 94107, USA), um die technische Stabilität unseres Dienstes durch Überwachung der Systemstabilität 
    und Ermittlung von Codefehlern zu verbessern. Sentry dient alleine diesen Zielen und wertet keine Daten zu Werbezwecken aus. Die Daten der Nutzer, 
    wie z.B. Angaben zum Gerät oder Fehlerzeitpunkt werden anonym erhoben und nicht personenbezogen genutzt sowie anschließend gelöscht. Weitere 
    Informationen hierzu finden Sie in der Datenschutzerklärung von Sentry: <a href="https://sentry.io/privacy/">https://sentry.io/privacy/</a>.
    
    <br><br><strong>Details zum Transfer in Drittstaaten</strong><br>Die Daten dieser Webseite und Matomo werden in
    Deutschland gehostet. Die Daten verlassen nicht die Europ&#228;ische Union.<br><br><strong>Rechte der betroffenen
      Personen</strong><br>
    <ul class="bullet">
      <li>Sie haben das Recht, von der AG Keim Auskunft &#252;ber die zu Ihrer Person gespeicherten personenbezogenen
        Daten
        gem&#228;&#223; Art. 15 DSGVO zu erhalten und&#x2F;oder unrichtig gespeicherte personenbezogene Daten
        gem&#228;&#223; Art. 16 DSGVO berichtigen zu lassen.</li>
      <li>Sie haben dar&#252;ber hinaus das Recht auf L&#246;schung (Art. 17 DSGVO) oder auf Einschr&#228;nkung der
        Verarbeitung (Art. 18 DSGVO) oder ein</li>
      <li>Erfolgt der Widerspruch im Rahmen eines Vertragsverh&#228;ltnisses kann dies zur Folge haben, dass eine
        Vertragsdurchf&#252;hrung nicht mehr m&#246;glich ist.</li>
      <li>Beruht die Verarbeitung der personenbezogenen Daten auf einer Einwilligung kann diese jederzeit widerrufen
        werden.
        Die Rechtm&#228;&#223;igkeit der Verarbeitung bleibt bis zum Widerruf unber&#252;hrt.</li>
      <li>Bitte wenden Sie sich zur Wahrnehmung Ihrer Rechte an den Datenschutzbeauftragten, E-Mail
        dsgvo&#64;dbvis.inf.uni-konstanz.de.</li>
      <li>Sie haben au&#223;erdem das Recht auf Beschwerde bei der Aufsichtsbeh&#246;rde, wenn Sie der Ansicht sind,
        dass
        die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die datenschutzrechtlichen Vorschriften
        verst&#246;&#223;t (Art. 77 DSGVO). Die zust&#228;ndige Aufsichtsbeh&#246;rde ist der Landesbeauftragte f&#252;r
        den
        Datenschutz und die Informationsfreiheit Baden-W&#252;rttemberg (<a
          href="https&#x3a;&#x2F;&#x2F;www&#x2e;baden&#x2d;wuerttemberg&#x2e;datenschutz&#x2e;de&#x29;"
          rel="noreferrer noopener">https:&#x2F;&#x2F;www.baden-wuerttemberg.datenschutz.de)</a>
    </ul><br><strong>Soziale Netzwerke</strong><br>In den sozialen Netzwerken informiert die AG Keim &#252;ber ihre
    aktuellen Aktivit&#228;ten und freut sich &#252;ber regen Austausch mit den Besucherinnen und Besuchern der
    Seiten.<br>Bitte beachten Sie dabei, dass die AG Keim keinen Einfluss auf die Datenerhebung und deren weitere
    Verwendung
    bei den Netzwerken und von diesen angebotenen Diensten hat. Bitte pr&#252;fen Sie sorgf&#228;ltig, welche
    pers&#246;nlichen Daten Sie den sozialen Netzwerken zur Verf&#252;gung stellen. Wir k&#246;nnen keine Haftung
    f&#252;r
    das Verhalten der Betreiber der sozialen Netzwerke sowie Dritter &#252;bernehmen.<br>Wir machen Sie
    ausdr&#252;cklich
    darauf aufmerksam, dass Twitter die Daten ihrer Nutzerinnen und Nutzer (z. B. pers&#246;nliche Informationen,
    IP-Adresse
    etc.) entsprechend ihrer Datenverwendungsrichtlinien abspeichern und f&#252;r gesch&#228;ftliche Zwecke nutzen.
    Inwiefern dies den europ&#228;ischen Datenschutzbestimmungen entspricht, welche Auswertungen und Verkn&#252;pfungen
    vorgenommen oder an wen die Daten weitergegeben werden, ist f&#252;r die Universit&#228;t nicht erkennbar. &#220;ber
    die
    Verarbeitung von Daten durch die sozialen Netzwerke k&#246;nnen Sie sich unter den folgenden Links informieren:<br>
  </mat-card>
</div>
