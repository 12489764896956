/* eslint-disable @typescript-eslint/quotes, quote-props */

import {
  Injectable
} from '@angular/core';
import {
  getDateTime
} from '../util/date-util';

@Injectable({
  providedIn: 'root'
})
export class VegaLinechartService {

  barChartTemplateSpec = {
    "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
    "data": {
      "values": [
        {"x": "2020-03-21", "y": 16.121966178918775},
        {"x": "2020-03-22", "y": 17.173398755804786},
        {"x": "2020-03-23", "y": 17.523876281433456},
        {"x": "2020-03-24", "y": 16.472443704547445},
        {"x": "2020-03-25", "y": 18.92578638394813},
        {"x": "2020-03-26", "y": 21.379129063348813},
        {"x": "2020-03-27", "y": 19.977218960834136},
        {"x": "2020-03-28", "y": 21.729606588977482},
        {"x": "2020-03-29", "y": 20.327696486462806},
        {"x": "2020-03-30", "y": 20.678174012091475},
        {"x": "2020-03-31", "y": 29.08963462717953},
        {"x": "2020-04-01", "y": 29.79058967843687},
        {"x": "2020-04-02", "y": 35.39823008849557},
        {"x": "2020-04-03", "y": 43.108735652326295},
        {"x": "2020-04-04", "y": 45.56207833172698},
        {"x": "2020-04-05", "y": 51.52019626741435},
        {"x": "2020-04-06", "y": 51.16971874178568},
        {"x": "2020-04-07", "y": 43.809690703583634},
        {"x": "2020-04-08", "y": 42.40778060106896},
        {"x": "2020-04-09", "y": 37.15061771663892},
        {"x": "2020-04-10", "y": 31.893454832208885},
        {"x": "2020-04-11", "y": 29.79058967843687},
        {"x": "2020-04-12", "y": 24.533426794006836},
        {"x": "2020-04-13", "y": 24.182949268378167},
        {"x": "2020-04-14", "y": 23.48199421712083},
        {"x": "2020-04-15", "y": 21.729606588977482},
        {"x": "2020-04-16", "y": 17.523876281433456},
        {"x": "2020-04-17", "y": 16.121966178918775},
        {"x": "2020-04-18", "y": 12.617190922632087},
        {"x": "2020-04-19", "y": 14.7200560764041},
        {"x": "2020-04-20", "y": 14.7200560764041},
        {"x": "2020-04-21", "y": 12.617190922632087},
        {"x": "2020-04-22", "y": 15.07053360203277},
        {"x": "2020-04-23", "y": 13.668623499518093},
        {"x": "2020-04-24", "y": 11.916235871374747},
        {"x": "2020-04-25", "y": 11.56575834574608},
        {"x": "2020-04-26", "y": 9.462893191974064},
        {"x": "2020-04-27", "y": 9.462893191974064},
        {"x": "2020-04-28", "y": 10.514325768860072},
        {"x": "2020-04-29", "y": 8.411460615088057},
        {"x": "2020-04-30", "y": 8.060983089459388},
        {"x": "2020-05-01", "y": 9.462893191974064},
        {"x": "2020-05-02", "y": 8.761938140716728},
        {"x": "2020-05-03", "y": 7.009550512573382},
        {"x": "2020-05-04", "y": 7.009550512573382},
        {"x": "2020-05-05", "y": 5.607640410058705},
        {"x": "2020-05-06", "y": 5.257162884430036},
        {"x": "2020-05-07", "y": 4.556207833172698},
        {"x": "2020-05-08", "y": 2.8038202050293526},
        {"x": "2020-05-09", "y": 3.1542977306580218},
        {"x": "2020-05-10", "y": 3.1542977306580218},
        {"x": "2020-05-11", "y": 3.1542977306580218},
        {"x": "2020-05-12", "y": 3.8552527819153597},
        {"x": "2020-05-13", "y": 3.1542977306580218},
        {"x": "2020-05-14", "y": 3.1542977306580218},
        {"x": "2020-05-15", "y": 2.8038202050293526},
        {"x": "2020-05-16", "y": 2.4533426794006834},
        {"x": "2020-05-17", "y": 2.4533426794006834},
        {"x": "2020-05-18", "y": 2.4533426794006834},
        {"x": "2020-05-19", "y": 1.4019101025146763},
        {"x": "2020-05-20", "y": 1.051432576886007},
        {"x": "2020-05-21", "y": 0.3504775256286691},
        {"x": "2020-05-22", "y": 0.7009550512573381},
        {"x": "2020-05-23", "y": 0.3504775256286691},
        {"x": "2020-05-24", "y": 1.051432576886007},
        {"x": "2020-05-25", "y": 1.051432576886007},
        {"x": "2020-05-26", "y": 1.7523876281433455},
        {"x": "2020-05-27", "y": 1.7523876281433455},
        {"x": "2020-05-28", "y": 1.7523876281433455},
        {"x": "2020-05-29", "y": 1.7523876281433455},
        {"x": "2020-05-30", "y": 1.7523876281433455},
        {"x": "2020-05-31", "y": 1.051432576886007},
        {"x": "2020-06-01", "y": 1.051432576886007},
        {"x": "2020-06-02", "y": 0.3504775256286691},
        {"x": "2020-06-03", "y": 0.3504775256286691},
        {"x": "2020-06-04", "y": 0.7009550512573381},
        {"x": "2020-06-05", "y": 0.3504775256286691},
        {"x": "2020-06-06", "y": 0.3504775256286691},
        {"x": "2020-06-07", "y": 0.3504775256286691},
        {"x": "2020-06-08", "y": 0.3504775256286691},
        {"x": "2020-06-09", "y": 0.3504775256286691},
        {"x": "2020-06-10", "y": 0.7009550512573381},
        {"x": "2020-06-11", "y": 0.3504775256286691},
        {"x": "2020-06-12", "y": 0.3504775256286691},
        {"x": "2020-06-13", "y": 0.3504775256286691},
        {"x": "2020-06-14", "y": 0.3504775256286691},
        {"x": "2020-06-15", "y": 0.3504775256286691},
        {"x": "2020-06-16", "y": 0.3504775256286691},
        {"x": "2020-06-17", "y": 0},
        {"x": "2020-06-18", "y": 0},
        {"x": "2020-06-19", "y": 0},
        {"x": "2020-06-20", "y": 0},
        {"x": "2020-06-21", "y": 0},
        {"x": "2020-06-22", "y": 0},
        {"x": "2020-06-23", "y": 0},
        {"x": "2020-06-24", "y": 0},
        {"x": "2020-06-25", "y": 0},
        {"x": "2020-06-26", "y": 0},
        {"x": "2020-06-27", "y": 0},
        {"x": "2020-06-28", "y": 0},
        {"x": "2020-06-29", "y": 0},
        {"x": "2020-06-30", "y": 0},
        {"x": "2020-07-01", "y": 0.3504775256286691},
        {"x": "2020-07-02", "y": 0.3504775256286691},
        {"x": "2020-07-03", "y": 0.3504775256286691},
        {"x": "2020-07-04", "y": 0.3504775256286691},
        {"x": "2020-07-05", "y": 0.3504775256286691},
        {"x": "2020-07-06", "y": 0.3504775256286691},
        {"x": "2020-07-07", "y": 0.3504775256286691},
        {"x": "2020-07-08", "y": 0},
        {"x": "2020-07-09", "y": 0},
        {"x": "2020-07-10", "y": 0},
        {"x": "2020-07-11", "y": 0.7009550512573381},
        {"x": "2020-07-12", "y": 1.051432576886007},
        {"x": "2020-07-13", "y": 1.051432576886007},
        {"x": "2020-07-14", "y": 1.4019101025146763},
        {"x": "2020-07-15", "y": 1.7523876281433455},
        {"x": "2020-07-16", "y": 1.7523876281433455},
        {"x": "2020-07-17", "y": 2.4533426794006834},
        {"x": "2020-07-18", "y": 2.4533426794006834},
        {"x": "2020-07-19", "y": 5.257162884430036},
        {"x": "2020-07-20", "y": 5.257162884430036},
        {"x": "2020-07-21", "y": 4.906685358801367},
        {"x": "2020-07-22", "y": 6.659072986944712},
        {"x": "2020-07-23", "y": 7.36002803820205},
        {"x": "2020-07-24", "y": 8.060983089459388},
        {"x": "2020-07-25", "y": 8.761938140716728},
        {"x": "2020-07-26", "y": 8.411460615088057},
        {"x": "2020-07-27", "y": 9.112415666345395},
        {"x": "2020-07-28", "y": 10.864803294488741},
        {"x": "2020-07-29", "y": 9.112415666345395},
        {"x": "2020-07-30", "y": 10.163848243231403},
        {"x": "2020-07-31", "y": 11.56575834574608},
        {"x": "2020-08-01", "y": 10.514325768860072},
        {"x": "2020-08-02", "y": 9.462893191974064},
        {"x": "2020-08-03", "y": 8.761938140716728},
        {"x": "2020-08-04", "y": 7.36002803820205},
        {"x": "2020-08-05", "y": 7.009550512573382},
        {"x": "2020-08-06", "y": 5.9581179356873735},
        {"x": "2020-08-07", "y": 4.906685358801367},
        {"x": "2020-08-08", "y": 5.607640410058705},
        {"x": "2020-08-09", "y": 3.8552527819153597},
        {"x": "2020-08-10", "y": 4.205730307544028},
        {"x": "2020-08-11", "y": 3.8552527819153597},
        {"x": "2020-08-12", "y": 4.205730307544028},
        {"x": "2020-08-13", "y": 7.710505563830719},
        {"x": "2020-08-14", "y": 7.36002803820205},
        {"x": "2020-08-15", "y": 7.36002803820205},
        {"x": "2020-08-16", "y": 8.060983089459388},
        {"x": "2020-08-17", "y": 7.710505563830719},
        {"x": "2020-08-18", "y": 9.112415666345395},
        {"x": "2020-08-19", "y": 12.967668448260754},
        {"x": "2020-08-20", "y": 10.514325768860072},
        {"x": "2020-08-21", "y": 11.21528082011741},
        {"x": "2020-08-22", "y": 10.514325768860072},
        {"x": "2020-08-23", "y": 10.514325768860072},
        {"x": "2020-08-24", "y": 10.864803294488741},
        {"x": "2020-08-25", "y": 9.813370717602734},
        {"x": "2020-08-26", "y": 7.009550512573382},
        {"x": "2020-08-27", "y": 9.112415666345395},
        {"x": "2020-08-28", "y": 7.710505563830719},
        {"x": "2020-08-29", "y": 8.060983089459388},
        {"x": "2020-08-30", "y": 9.112415666345395},
        {"x": "2020-08-31", "y": 8.761938140716728},
        {"x": "2020-09-01", "y": 8.411460615088057},
        {"x": "2020-09-02", "y": 9.112415666345395},
        {"x": "2020-09-03", "y": 6.659072986944712},
        {"x": "2020-09-04", "y": 7.36002803820205},
        {"x": "2020-09-05", "y": 9.462893191974064},
        {"x": "2020-09-06", "y": 8.411460615088057},
        {"x": "2020-09-07", "y": 8.411460615088057},
        {"x": "2020-09-08", "y": 8.761938140716728},
        {"x": "2020-09-09", "y": 7.36002803820205},
        {"x": "2020-09-10", "y": 7.710505563830719},
        {"x": "2020-09-11", "y": 9.112415666345395},
        {"x": "2020-09-12", "y": 9.813370717602734},
        {"x": "2020-09-13", "y": 9.813370717602734},
        {"x": "2020-09-14", "y": 9.813370717602734},
        {"x": "2020-09-15", "y": 11.21528082011741},
        {"x": "2020-09-16", "y": 14.369578550775433},
        {"x": "2020-09-17", "y": 17.173398755804786},
        {"x": "2020-09-18", "y": 18.57530885831946},
        {"x": "2020-09-19", "y": 16.121966178918775},
        {"x": "2020-09-20", "y": 15.771488653290108},
        {"x": "2020-09-21", "y": 15.771488653290108},
        {"x": "2020-09-22", "y": 16.822921230176114},
        {"x": "2020-09-23", "y": 14.019101025146764},
        {"x": "2020-09-24", "y": 10.163848243231403},
        {"x": "2020-09-25", "y": 7.009550512573382},
        {"x": "2020-09-26", "y": 7.36002803820205},
        {"x": "2020-09-27", "y": 8.060983089459388},
        {"x": "2020-09-28", "y": 8.060983089459388},
        {"x": "2020-09-29", "y": 6.659072986944712},
        {"x": "2020-09-30", "y": 5.607640410058705},
        {"x": "2020-10-01", "y": 5.607640410058705},
        {"x": "2020-10-02", "y": 20.327696486462806},
        {"x": "2020-10-03", "y": 18.92578638394813},
        {"x": "2020-10-04", "y": 17.87435380706212},
        {"x": "2020-10-05", "y": 22.43056164023482},
        {"x": "2020-10-06", "y": 23.48199421712083},
        {"x": "2020-10-07", "y": 26.636291947778847},
        {"x": "2020-10-08", "y": 33.295364934723565},
        {"x": "2020-10-09", "y": 20.678174012091475},
        {"x": "2020-10-10", "y": 20.678174012091475},
        {"x": "2020-10-11", "y": 20.678174012091475},
        {"x": "2020-10-12", "y": 27.337246999036186},
        {"x": "2020-10-13", "y": 27.337246999036186},
        {"x": "2020-10-14", "y": 28.388679575922193},
        {"x": "2020-10-15", "y": 25.58485937089284},
        {"x": "2020-10-16", "y": 27.337246999036186},
        {"x": "2020-10-17", "y": 27.337246999036186},
        {"x": "2020-10-18", "y": 27.337246999036186},
        {"x": "2020-10-19", "y": 31.893454832208885},
        {"x": "2020-10-20", "y": 33.295364934723565},
        {"x": "2020-10-21", "y": 38.903005344782265},
        {"x": "2020-10-22", "y": 45.21160080609831},
        {"x": "2020-10-23", "y": 49.06685358801367},
        {"x": "2020-10-24", "y": 60.98308945938841},
        {"x": "2020-10-25", "y": 60.98308945938841},
        {"x": "2020-10-26", "y": 67.64216244633313},
        {"x": "2020-10-27", "y": 89.02129150968194},
        {"x": "2020-10-28", "y": 103.39087006045737},
        {"x": "2020-10-29", "y": 121.2652238675195},
        {"x": "2020-10-30", "y": 139.13957767458163},
        {"x": "2020-10-31", "y": 127.22334180320686},
        {"x": "2020-11-01", "y": 127.22334180320686},
        {"x": "2020-11-02", "y": 140.19101025146765},
        {"x": "2020-11-03", "y": 141.24244282835363},
        {"x": "2020-11-04", "y": 145.44817313589766},
        {"x": "2020-11-05", "y": 148.95294839218434},
        {"x": "2020-11-06", "y": 146.85008323841234},
        {"x": "2020-11-07", "y": 159.8177516866731},
        {"x": "2020-11-08", "y": 159.8177516866731},
        {"x": "2020-11-09", "y": 182.59879085253658},
        {"x": "2020-11-10", "y": 162.27109436607378},
        {"x": "2020-11-11", "y": 150.00438096907035},
        {"x": "2020-11-12", "y": 142.6443529308683},
        {"x": "2020-11-13", "y": 139.4900552002103},
        {"x": "2020-11-14", "y": 126.52238675194953},
        {"x": "2020-11-15", "y": 131.77954963637956},
        {"x": "2020-11-16", "y": 134.23289231578025},
        {"x": "2020-11-17", "y": 137.3871900464383},
        {"x": "2020-11-18", "y": 147.55103828966966},
        {"x": "2020-11-19", "y": 155.96249890475772},
        {"x": "2020-11-20", "y": 149.30342591781303},
        {"x": "2020-11-21", "y": 159.11679663541577},
        {"x": "2020-11-22", "y": 153.85963375098572},
        {"x": "2020-11-23", "y": 135.63480241829492},
        {"x": "2020-11-24", "y": 135.28432489266626},
        {"x": "2020-11-25", "y": 124.06904407254885},
        {"x": "2020-11-26", "y": 99.18513975291334},
        {"x": "2020-11-27", "y": 113.90519582931745},
        {"x": "2020-11-28", "y": 117.05949355997546},
        {"x": "2020-11-29", "y": 117.40997108560414},
        {"x": "2020-11-30", "y": 103.39087006045737},
        {"x": "2020-12-01", "y": 105.84421273985805},
        {"x": "2020-12-02", "y": 111.45185314991677},
        {"x": "2020-12-03", "y": 130.02716200823622},
        {"x": "2020-12-04", "y": 120.56426881626216},
        {"x": "2020-12-05", "y": 111.80233067554542},
        {"x": "2020-12-06", "y": 118.46140366249016},
        {"x": "2020-12-07", "y": 118.8118811881188},
        {"x": "2020-12-08", "y": 118.46140366249016},
        {"x": "2020-12-09", "y": 114.60615088057479},
        {"x": "2020-12-10", "y": 113.55471830368877},
        {"x": "2020-12-11", "y": 114.95662840620346},
        {"x": "2020-12-12", "y": 126.52238675194953},
        {"x": "2020-12-13", "y": 120.91474634189083},
        {"x": "2020-12-14", "y": 122.66713397003417},
        {"x": "2020-12-15", "y": 122.66713397003417},
        {"x": "2020-12-16", "y": 132.4805046876369},
        {"x": "2020-12-17", "y": 147.90151581529835},
        {"x": "2020-12-18", "y": 152.10724612284238},
        {"x": "2020-12-19", "y": 154.91106632787174},
        {"x": "2020-12-20", "y": 153.50915622535703},
        {"x": "2020-12-21", "y": 158.41584158415841},
        {"x": "2020-12-22", "y": 162.9720494173311},
        {"x": "2020-12-23", "y": 178.0425830193639},
        {"x": "2020-12-24", "y": 136.68623499518094},
        {"x": "2020-12-25", "y": 147.55103828966966},
        {"x": "2020-12-26", "y": 128.9757294313502},
        {"x": "2020-12-27", "y": 132.13002716200825},
        {"x": "2020-12-28", "y": 135.28432489266626},
        {"x": "2020-12-29", "y": 140.19101025146765},
        {"x": "2020-12-30", "y": 123.01761149566283},
        {"x": "2020-12-31", "y": 127.92429685446422},
        {"x": "2021-01-01", "y": 114.25567335494611},
        {"x": "2021-01-02", "y": 140.19101025146765}
      ]
    },
    // "transform": [
    //   {
    //     "sort": [{"field": "x"}],
    //     "window": [{"op": "sum", "field": "y", "as": "y2"}]
    //   }
    // ],
    "vconcat": [
      {
        "layer": [
          {
            "mark": {"stroke": "#adadad", "type": "line"},
            "encoding": {
              "x": {
                "field": "x",
                "type": "temporal",
                "title": "",
                "timeUnit": "yearmonthdate"
              },
              "y": {
                "field": "y2",
                "type": "quantitative",
                "title": "Accumulated",
                "axis": {
                  "titleColor": "#adadad",
                  "orient": "right",
                  "labelColor": "#adadad",
                  "tickColor": "#adadad"
                }
              }
            }
          },
          {
            "layer": [
              {
                "mark": "rule",
                "encoding": {"y": {"datum": 165}, "color": {"value": "darkgrey"}}
              },
              {
                "mark": "rule",
                "encoding": {"y": {"datum": 100}, "color": {"value": "darkgrey"}}
              },
              {
                "mark": "rule",
                "encoding": {"y": {"datum": 50}, "color": {"value": "darkgrey"}}
              },
              {
                "mark": "rule",
                "encoding": {"y": {"datum": 35}, "color": {"value": "darkgrey"}}
              },
              {
                "mark": {"type": "line"},
                "encoding": {
                  "x": {
                    "field": "x",
                    "type": "temporal",
                    "title": "",
                    "timeUnit": "yearmonthdate"
                  },
                  "y": {
                    "field": "y",
                    "type": "quantitative",
                    "aggregate": "mean",
                    "scale": {"type": "linear"}
                  }
                }
              },
              {
                "mark": {"type": "circle", "tooltip": true},
                "encoding": {
                  "x": {
                    "field": "x",
                    "type": "temporal",
                    "title": "",
                    "axis": {"title": null},
                    "scale": {"domain": {"selection": "brush"}},
                    "timeUnit": "yearmonthdate"
                  },
                  "y": {
                    "field": "y",
                    "type": "quantitative",
                    "title": "New cases per 100k / 7days",
                    "aggregate": "mean",
                    "scale": {"type": "linear"}
                  },
                  "color": {
                    "condition": [
                      {"test": "datum.y > 165", "value": "darkred"},
                      {"test": "datum.y > 100", "value": "orangered"},
                      {"test": "datum.y > 50", "value": "orange"}
                      // {"test": "datum.y < 26.25", "value": "rgb(255, 166, 102)"},
                      // {"test": "datum.y < 35", "value": "rgb(255, 136, 51)"},
                      // {"test": "datum.y < 40", "value": "rgb(254, 85, 1)"},
                      // {"test": "datum.y < 45", "value": "rgb(228, 77, 1)"},
                      // {"test": "datum.y < 50", "value": "rgb(203, 68, 1)"},
                      // {"test": "datum.y < 100", "value": "rgb(184, 0, 135)"},
                      // {"test": "datum.y < 150", "value": "rgb(153, 0, 112)"},
                      // {"test": "datum.y < 200", "value": "rgb(102, 0, 75)"},
                      // {"test": "datum.y < 350", "value": "rgb(77, 77, 77)"},
                      // {"test": "datum.y < 500", "value": "rgb(51, 51, 51)"},
                      // {"test": "datum.y >= 500", "value": "rgb(0, 0, 0)"},
                    ]
                  },
                  "tooltip": [
                    {"field": "x", "title": "Date", "type": "temporal"},
                    {
                      "field": "y",
                      "title": "New cases per 100k / 7days",
                      "format": ",.2f"
                    },
                    {"field": "y2", "title": "Accumulated", "format": ",.2f"}
                  ]
                }
              }
            ]
          }
        ],
        "width": 700,
        "height": 150,
        "resolve": {"scale": {"y": "independent"}}
      },
      {
        "mark": "line",
        "width": 700,
        "height": 40,
        "selection": {"brush": {"type": "interval", "encodings": ["x"]}},
        "encoding": {
          "x": {
            "field": "x",
            "type": "temporal",
            "axis": {"title": ""},
            "title": "",
            "timeUnit": "yearmonthdate"
          },
          "y": {
            "field": "y",
            "type": "quantitative",
            "axis": {"tickCount": 2, "grid": false, "title": null},
            "aggregate": "mean",
            "scale": {"type": "linear"}
          }
        }
      }
    ]
  };

  compileChart(
    data: {
      x: string;y: number;
    } [],
    chartOptions: {
      xAxisTitle: string;
      yAxisTitle: string;
      yAxis2Title: string;
      width: number | 'container';
      height: number;
      regression?: {
        from: string;
        to: string;
      };
      incidenceRules: boolean;
      tempGranularity?: string;
      scaleType: string;
    }
  ): any {
    if (!data) {
      return null;
    }

    const spec = JSON.parse(JSON.stringify(this.barChartTemplateSpec));

    // inject data values
    spec.data.values = data;

    // also overwrite the title
    spec.vconcat[0].layer[1].layer[4].encoding.x.title = chartOptions.xAxisTitle || '';
    spec.vconcat[1].encoding.x.title = chartOptions.xAxisTitle || '';
    // spec.layer[3].encoding.tooltip[0].title = chartOptions.xAxisTitle || '';

    spec.vconcat[0].layer[1].layer[3].encoding.y.title = chartOptions.yAxisTitle || '';
    spec.vconcat[0].layer[1].layer[5].encoding.y.title = chartOptions.yAxisTitle || '';
    spec.vconcat[0].layer[1].layer[5].encoding.tooltip[1].title = chartOptions.yAxisTitle || '';

    spec.vconcat[0].layer[0].encoding.y.title = chartOptions.yAxis2Title || '';
    spec.vconcat[0].layer[1].layer[5].encoding.tooltip[2].title = chartOptions.yAxis2Title || '';

    spec.vconcat[0].width = chartOptions.width;
    spec.vconcat[0].height = chartOptions.height;

    spec.vconcat[1].width = chartOptions.width;
    spec.vconcat[1].height = 40;

    if (chartOptions.tempGranularity) {
      spec.vconcat[0].layer[1].layer[4].encoding.x.timeUnit = chartOptions.tempGranularity;
      spec.vconcat[0].layer[1].layer[5].encoding.x.timeUnit = chartOptions.tempGranularity;
      spec.vconcat[1].encoding.x.timeUnit = chartOptions.tempGranularity;

      spec.vconcat[0].layer[1].layer[4].encoding.y.aggregate = 'mean';
      spec.vconcat[0].layer[1].layer[5].encoding.y.aggregate = 'mean';
      spec.vconcat[1].encoding.y.aggregate = 'mean';
    }

    spec.vconcat[0].layer[0].encoding.y.scale = {type: chartOptions.scaleType};
    spec.vconcat[0].layer[1].layer[4].encoding.y.scale = {type: chartOptions.scaleType};
    spec.vconcat[0].layer[1].layer[5].encoding.y.scale = {type: chartOptions.scaleType};
    spec.vconcat[1].encoding.y.scale = {type: chartOptions.scaleType};

    if (chartOptions.regression) {
      const from = getDateTime(chartOptions.regression.from).valueOf();
      const to = getDateTime(chartOptions.regression.to).valueOf();
      spec.vconcat[0].layer[1].layer.push({
        "mark": {
          "type": "line",
          "color": "black"
        },
        "transform": [{
            "filter": "datum.x >= " + from
          },
          {
            "filter": "datum.x <= " + to
          },
          {
            "regression": "y",
            "on": "x",
            "extent": [from, to]
          }
          // {"regression": "y", "on": "x"}
        ],
        "encoding": {
          "x": {
            "field": "x",
            "type": "temporal",
            "scale": {
              "domain": {
                "selection": "brush"
              }
            }
          },
          "y": {
            "field": "y",
            "type": "quantitative"
          }
        }
      });

      if (!chartOptions.incidenceRules) {
        // removes the horizontal rules from the chart
        spec.vconcat[0].layer[1].layer.splice(0, 4);
        delete spec.vconcat[0].layer[1].layer[1].encoding.color;
      }

      // spec.layer.push(
      //   {
      //     "transform": [
      //       {"filter": {"field": "x", "gte": ''+from}},
      //       {"filter": {"field": "x", "lte": ''+to}},
      //       {
      //         "regression": "y",
      //         "on": "x",
      //         "params": true,
      //         "extent": [from, to]
      //       },
      //       {"calculate": "'R²: '+format(datum.rSquared, '.2f')", "as": "R2"}
      //     ],
      //     "mark": {
      //       "type": "text",
      //       "color": "firebrick",
      //       "x": "width",
      //       "align": "right",
      //       "y": -5
      //     },
      //     "encoding": {"text": {"type": "nominal", "field": "R2"}}
      //   });
    }

    return spec;
  }
}
