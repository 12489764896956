<!--h1 mat-dialog-title></h1-->
<div class="main" mat-dialog-content>

  <div class="row">
    <div class="group">
      <div class="description" i18n="ls-name|name of the group@@lsName">Datenanalyse und Visualisierung</div>
      <ul class="flex-container">
        <li class="flex-item">
          <div class="groupcontainer">
            <img src="/assets/dbviscube.png" alt="Smiley face" width="80px;">
          </div>
        </li>
        <li>
          <div class="groupdesc" i18n="introduction-tool|introduces the tool and LS@@teamToolIntro"> Dieses Tool wird entwickelt von der <a href="https://vis.uni.kn">AG Datenanalyse und
            Visualisierung</a> von Prof. Dr. Daniel Keim an der Universität Konstanz.
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="group">
      <div class="description" i18n="organisation-and-communication|department@@orgComm">Organisation und Kommunikation</div>
      <ul class="flex-container">
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/jentner/" target="_blank">
              <img src="/assets/jentner.jpg" alt="Wolfgang Jentner" width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/jentner/" target="_blank">Wolfgang
              Jentner</a></figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/buchmueller/" target="_blank"><img
              src="/assets/juri.jpg" alt="Juri Buchmüller" width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/buchmueller/" target="_blank">Juri
              Buchmüller</a></figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/el-assady/" target="_blank"><img src="/assets/Menna.jpg"
                                                                                                 alt="Mennatallah El-Assady"
                                                                                                 width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/el-assady/" target="_blank">Mennatallah
              El-Assady</a></figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.jgoertler.com/" target="_blank"><img src="/assets/jochen_goertler.jpg"
                                                                      alt="Jochen Görtler" width="80px;"></a>
            <figcaption><a href="https://www.jgoertler.com/" target="_blank">Jochen Görtler</a></figcaption>
          </div>
        </li>
      </ul>
    </div>
    <div class="group">
      <div class="description" i18n="visualization and design|department@@visDesign">Visualisierung und Design</div>
      <ul class="flex-container">
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/sevastjanova" target="_blank"><img
              src="/assets/sevastjanova.jpg" alt="Rita Sevastjanova" width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/sevastjanova" target="_blank">Rita
              Sevastjanova</a></figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/miller" target="_blank"><img src="/assets/miller.jpg"
                                                                                             alt="Matthias Miller"
                                                                                             width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/miller" target="_blank">Matthias Miller</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/kraus/" target="_blank"><img src="/assets/kraus.jpg"
                                                                                             alt="Matthias Kraus"
                                                                                             width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/kraus/" target="_blank">Matthias Kraus</a>
            </figcaption>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="group">
      <div class="description" i18n="database and processing|department@@dataHandlingProcessing">Datenhaltung und -verarbeitung</div>
      <ul class="flex-container">
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/seebacher" target="_blank"><img
              src="/assets/seebacher.jpg"
              alt="Daniel Seebacher"
              width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/seebacher" target="_blank">Daniel
              Seebacher</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/cakmak" target="_blank"><img src="/assets/cakmak.jpg"
                                                                                             alt="Eren Cakmak"
                                                                                             width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/cakmak" target="_blank">Eren Cakmak</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/schlegel" target="_blank"><img
              src="/assets/udo-schlegel.jpg" alt="Udo Schlegel" width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/schlegel" target="_blank">Udo Schlegel</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/maxfischer" target="_blank"><img
              src="/assets/fischer.jpg"
              alt="Maximilian Fischer"
              width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/maxfischer" target="_blank">Maximilian
              Fischer</a></figcaption>
          </div>
        </li>
      </ul>
    </div>
    <div class="group">
      <div class="description" i18n="infrastructure|department@@infra">Infrastruktur</div>
      <ul class="flex-container">
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/sperrle" target="_blank"><img src="/assets/fabian.jpg"
                                                                                              alt="Fabian Sperrle"
                                                                                              width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/sperrle" target="_blank">Fabian Sperrle</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/sharinghousen" target="_blank"><img
              src="/assets/matt.jpg"
              alt="Matthew Sharinghousen"
              width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/sharinghousen" target="_blank">Matthew
              Sharinghousen</a></figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/spinner" target="_blank"><img
              src="/assets/thilo-spinner.jpg" alt="Thilo Spinner" width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/spinner" target="_blank">Thilo Spinner</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/jentner/" target="_blank"><img src="/assets/jentner.jpg"
                                                                                               alt="Wolfgang Jentner"
                                                                                               width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/jentner/" target="_blank">Wolfgang
              Jentner</a>
            </figcaption>
          </div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row">
    <div class="group">
      <div i18n="advice@@consult" class="description">Unterstützung und Beratung</div>
      <ul class="flex-container">
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/keim" target="_blank"><img src="/assets/Daniel_Keim.jpg"
                                                                                           alt="Prof Dr. Daniel Keim"
                                                                                           width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/keim" target="_blank">Prof Dr. Daniel
              Keim</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.cgmi.uni-konstanz.de/en/persons/prof-dr-oliver-deussen/" target="_blank"><img
              src="/assets/oliver.jpg" alt="Prof Dr. Oliver Deussen" width="80px;"></a>
            <figcaption><a href="https://www.cgmi.uni-konstanz.de/en/persons/prof-dr-oliver-deussen/" target="_blank">Prof
              Dr. Oliver Deussen</a></figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/stoffel" target="_blank"><img src="/assets/fstoffel.jpg"
                                                                                              alt="Dr. Florian Stoffel"
                                                                                              width="80px;"></a>
            <figcaption><a href="https://www.polianalytics.de/" target="_blank">Dr. Florian
              Stoffel</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.glkn.de/glkn/ansprechpartner/personen/Kabitz-Hans-Joachim.php" target="_blank"><img
              src="/assets/kabitz.jpg" alt="Prof. Dr. Hans-Joachim Kabitz" width="80px;"></a>
            <figcaption><a href="https://www.glkn.de/glkn/ansprechpartner/personen/Kabitz-Hans-Joachim.php"
                           target="_blank">Prof.
              Dr. Hans-Joachim Kabitz</a></figcaption>
          </div>
        </li>
      </ul>
    </div>


    <div class="group">
      <div i18n="testing|department@@testing" class="description">Tests und Qualitätssicherung</div>
      <ul class="flex-container">
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/streeb/" target="_blank"><img src="/assets/streeb.jpg"
                                                                                           alt="Dirk Streeb"
                                                                                           width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/streeb/" target="_blank">Dirk Streeb</a>
            </figcaption>
          </div>
        </li>
        <li class="flex-item">
          <div class="contributorcontainer">
            <a href="https://www.vis.uni-konstanz.de/mitglieder/meschenmoser/" target="_blank"><img
              src="/assets/meschenmoser.jpg" alt="Philipp Meschenmoser" width="80px;"></a>
            <figcaption><a href="https://www.vis.uni-konstanz.de/mitglieder/meschenmoser/" target="_blank">
              Philipp Meschenmoser</a></figcaption>
          </div>
        </li>
      </ul>
    </div>
  </div>

</div>

<div class="bottom-panel" mat-dialog-actions>
  <div class="versions">
    <p>Version: 
      <a *ngIf="frontendVersion$" href="https://gitlab.dbvis.de/jentner/covid-19-vis/-/commit/{{ frontendVersion$ | async }}">{{ frontendVersion$ | async }}</a>
      <span *ngIf="!frontendVersion$" i18n="development@@versionDevelopmentFlag">development</span>
    </p>
    <p>API Version: 
      <a *ngIf="apiVersion$" href="https://gitlab.dbvis.de/jentner/covid-19-vis/-/commit/{{ apiVersion$ | async }}">{{ apiVersion$ | async }}</a>
      <span *ngIf="!apiVersion$" i18n="unknown@@versionUnknownFlag">unknown</span>
    </p>
    <p>Tile Server Version: 
      <a *ngIf="tileServerVersion$" href="https://gitlab.dbvis.de/jentner/covid-19-vis/-/commit/{{ tileServerVersion$ | async }}">{{ tileServerVersion$ | async }}</a>
      <span *ngIf="!tileServerVersion$" i18n="unknown@@versionUnknownFlag">unknown</span>
    </p>
  </div>

  <div>
    <button class="mat-raised-button" (click)="close()" i18n="button@@close">Schließen</button>
  </div>
</div>
