<div class="outer">
  <mat-card>
    <h1 i18n="@@subEmailSubscription">E-Mail-Benachrichtigungen</h1>

    <p i18n="@@subHowWork"><strong>Wie funktioniert es?</strong></p>
    <p i18n="@@subIntro">Freiwillige (Crowdsourcer) sammeln laufend die Daten der Kreise, sobald diese auf den entsprechenden Webseiten veröffentlicht werden. Wir erhalten diese Daten und können Dir, sobald ein Update für einen Kreis vorliegt, eine E-Mail zusenden mit den neuesten Daten des Kreises. Du bekommst dann pro ausgewähltem Kreis und pro Tag eine Nachricht von uns. Du kannst diesen Service jederzeit abbestellen mit einem Link der mit jeder Nachricht verschickt wird.</p>

    <span class="hidden-placeholder" i18n='@@subSubSuccess' #lsuccess>Bitte prüfe Dein Postfach um deine E-Mail-Adresse zu verifizieren.</span>
    <span class="hidden-placeholder" i18n='@@subUnsubSuccess' #lunsubsuccess>Du hast deine E-Mail-Benachrichtigungen erfolgreich abbestellt.</span>
    <span class="hidden-placeholder" i18n='@@subVerifySuccess' #lsubverifysuccess>Deine E-Mail-Adresse wurde erfolgreich verifiziert.</span>
    <span class="hidden-placeholder" i18n='@@subUpdateSuccess' #lsubupdatesuccess>Benachrichtigungs-Einstellungen wurden erfolgreich aktualisiert.</span>

    <h4 *ngIf="success" style="color:green;">{{ success }}</h4>

    <h4>  
      <mat-error *ngIf="error">
        {{ error }}
      </mat-error>
    </h4>


    <form *ngIf="!error" class="example-form" [formGroup]="checkoutForm" (ngSubmit)="onSubmit(checkoutForm.value)">
      <mat-form-field class="example-full-width">
        <mat-label i18n="@@subEmail">E-Mail</mat-label>
        <input matInput [errorStateMatcher]="matcher" placeholder="Ex. pat@example.com" formControlName="email">
        <mat-hint *ngIf="subId > 0" i18n="@@subMakeNewHint">Falls du deine E-Mail-Adresse ändern möchtest bestelle bitte eine neue Benachrichtigung.</mat-hint>
        <mat-error i18n="@@subEmailCorrectError" *ngIf="checkoutForm.get('email').hasError('email') && !checkoutForm.get('email').hasError('required')">
          Bitte gib eine korrekte E-Mail-Adresse an.
        </mat-error>
        <mat-error *ngIf="checkoutForm.get('email').hasError('required')" i18n="@@subEmailRequiredError">
          Eine E-Mail-Adresse ist notwendig.
        </mat-error>
      </mat-form-field>


      <p i18n="@@subSelectCounty"><strong>Kreis auswählen</strong><br>(mit der Enter-Taste bestätigen)</p>

      <app-hospital-search 
        class="search"
        [data]="counties$" 
        (selectedHospital)="addCounty($event)" 
        [reset]="resetSearch"
      ></app-hospital-search>

      <div formArrayName="counties">
        <p i18n="@@subSelectedCounties"><strong>Ausgewählte Kreise</strong></p>

        <mat-error *ngIf="counties.controls.length === 0" i18n="@@subNoCountiesSelectedError">Es muss mindestens ein Kreis ausgewählt werden.</mat-error>

        <mat-list role="list">
          <mat-list-item role="listitem" *ngFor="let alias of counties.controls; let i=index">
            {{ alias.get('desc').value }} {{ alias.get('name').value }}
            <button mat-icon-button aria-label="Example icon button with a vertical three dot icon" (click)="deleteCounty(i)">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-list-item>
        </mat-list>
      
      </div>

      <div>
        <p i18n="@@subLanguage"><strong>Sprache</strong></p>
        <mat-radio-group
          class="example-radio-group"
          aria-labelledby="example-radio-group-label"
          formControlName="lang">
          <mat-radio-button class="example-radio-button" value="de" i18n="@@subLangGerman">Deutsch</mat-radio-button>
          <mat-radio-button class="example-radio-button" value="en" i18n="@@subLangEnglish">Englisch</mat-radio-button>
        </mat-radio-group>
      </div>

      <div>
        <p i18n="@@subTermsAndConditions"><strong>Nutzungsbedingungen</strong></p>

        <div class="checkboxes">
          <mat-checkbox class="example-margin my-checkbox" formControlName="terms" i18n="@@subTerms1">Ich willige ein, dass meine E-Mail-Adresse zum Zweck der Benachrichtigungen verschlüsselt gespeichert wird. Die E-Mail-Adresse wird nicht an Dritte weiter gegeben. <a routerLink="/overview/gdpr">Unsere Datenschutzrichtlinien sind hier einzusehen.</a></mat-checkbox>

        <mat-checkbox class="example-margin my-checkbox" formControlName="terms2" i18n="@@subTerms2">Mir ist bewusst, dass CoronaVis keine Gewähr auf die Korrektheit der Zahlen geben kann.</mat-checkbox>
        </div>
      </div>

      <div class="buttons">
        <button *ngIf="!(subId > 0)" mat-raised-button color="primary" type="submit" [disabled]="!checkoutForm.valid || counties.controls.length === 0" i18n="@@subBtnSubscribe">E-Mail-Benachrichtungen bestellen</button> 
        <button *ngIf="subId > 0" mat-raised-button color="primary" type="submit" [disabled]="!checkoutForm.valid || counties.controls.length === 0" i18n="@@subBtnChange">E-Mail-Benachrichtungen ändern</button> 
        <button *ngIf="subId > 0" (click)="unsubscribe()" mat-raised-button color="warn" i18n="@@subBtnUnsubscribe">E-Mail-Benachrichtungen abbestellen</button>
      </div>
    </form>
  </mat-card>
</div>
