<div class="container" [ngClass]="{ 'mobile': gridNumCols === 1 }">
    <div class="grid">
        <mat-grid-list [cols]="gridNumCols" rowHeight="1:1">
            <mat-grid-tile class="mytile" *ngFor="let d of dataBlobBeds" [routerLink]="['/map', { mlo: d.moUrl, mls: d.mlsUrl}]">

                

                <div class="mapcontainer" *ngIf="d.data | withLoading | async as data">
                    <mat-spinner class="loading" *ngIf="data.loading"></mat-spinner>
                    <app-d3-choropleth-map class="map" *ngIf="data.value" [data]="data.value"></app-d3-choropleth-map>
                </div>


                <mat-grid-tile-footer class="footer">
                    <h4>{{ d.aggLevelFriendly | translate }}</h4>
                    <span class="spacer"></span>
                    <h4>{{ d.bedTypeFriendly | translate }}</h4>
                </mat-grid-tile-footer>
                
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <div class="text">
        <h1>Übersicht Intensivbettenkapazitäten</h1>

        <div *ngIf="aggregatedDiviStatistics">
            <p class="stats" i18n="@@infoboxNumberOfHospitals">{{ aggregatedDiviStatistics.num_hospitals | number }} bundesweit gemeldete Einrichtungen</p>
            <p class="stats" i18n="@@infoboxLastUpdate">Letzter Stand: {{ aggregatedDiviStatistics.timestamp | date:'full' }}</p>
        </div>

        <div class="legend-wrapper">
            <span>Legende: </span>
            <app-bed-inline-legend class="align-left"></app-bed-inline-legend>
        </div>

        <p>
            Quelle: <a i18n="@@infoboxDIVI" href="https://www.intensivregister.de/#/intensivregister" target="_blank">DIVI Intensivregister</a>
        </p>

        <h3>Erklärung</h3>

        <p>In der Übersicht sind die Bettenkapazitäten nach verschiedenen Bettenkategorien (ICU low, ICU high & ECMO) und auch nach diversen
            Aggregationsstufen (Landkreise, Regierungsbezirke, Bundesländer) aufgelistet. Ein Klick auf eine Ansicht bringt Sie zu unserer interaktiven Karte in der Sie weitere Einstellungen vornehmen können.
        </p>
    </div>
</div>