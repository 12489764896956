<div class="ebrake-legend" #legend>
    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <line class="sunholiday" x1="1" x2="1" y1="0" y2="20"></line>
            <line class="sunholiday" x1="39" x2="39" y1="0" y2="20"></line>
        </svg>

        <div i18n="@@ebrakeOverviewSunHoliday">Sonn- und Feiertage</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <rect class="bar under100" width="5" height="5" x="17.5" y="7.5"></rect>
        </svg>

        <div i18n="@@ebrakeOverview7DIncicenceBelow100">7-Tage-Inzidenz unter 100</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <rect class="bar ebrake100" width="10" height="10" x="15" y="5"></rect>
        </svg>

        <div i18n="@@ebrakeOverview7DIncicenceOber100">7-Tage-Inzidenz über 100</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <rect class="bar ebrake165" width="15" height="15" x="12.5" y="2.5"></rect>
        </svg>

        <div i18n="@@ebrakeOverview7DIncicenceOver165">7-Tage-Inzidenz über 165</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder" style="stroke: darkgray;"></rect>
            <rect style="fill:white;stroke:darkgray;" width="40" height="20" />
        </svg>

        <div i18n="@@ebrakeOverviewEbrakeNone">Keine Bundesnotbremse</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <rect class="bg ebrake100" width="40" height="20" />
        </svg>

        <div i18n="@@ebrakeOverviewEbrake100">Bundesnotbremse (100)</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <rect class="bg ebrake165" width="40" height="20" />
        </svg>

        <div i18n="@@ebrakeOverviewEbrake165">Bundesnotbremse (165)</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <rect class="bg ebrakeunknown" width="40" height="20" />
        </svg>

        <div i18n="@@ebrakeOverviewEbrakeUnknown">Unbekannt (keine Prognose möglich)</div>
    </div>

    <div class="item">
        <svg class="legend-chart" width="40" height="20">
            <rect width="40" height="20" x="0" y="0" class="lborder"></rect>
            <rect class="prognosis" width="40" height="20" />
        </svg>

        <div i18n="@@ebrakeOverviewEbrakePrognosis">Prognose (Änderungen möglich)</div>
    </div>

    <div class="item">
        <a i18n="@@ebrakeOverviewEbrakeMoreInformation" href="https://www.bundesgesundheitsministerium.de/service/gesetze-und-verordnungen/guv-19-lp/4-bevschg-faq.html" target="_blank">Mehr Informationen zum 4. Bevölkerungsschutzgesetz <mat-icon inline="true">launch</mat-icon></a>
    </div>
</div>

<div *ngIf="data" class="datatime">
    <span i18n="@@ebrakeOverviewDataLastUpdated">Datenstand: {{ data.last_updated | date: 'long' }}</span>; 
    <span i18n="@@ebrakeOverviewDataLastChecked">Zuletzt abgerufen: {{ data.last_checked| date: 'long' }}</span>
</div>

<svg class="ebrake-chart" #svg>
    <defs>
        <pattern id="diagonalHatch" patternUnits="userSpaceOnUse" width="4" height="8" patternTransform="rotate(-45 2 2)">
            <path d="M -1,2 l 6,0" stroke="#000000" stroke-width=".5"/>
        </pattern>
    </defs>

</svg>

<div style="padding-left:10px;">
    <app-data-source source="rki_incidences"></app-data-source>
</div>