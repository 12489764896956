export const APP_CONFIG_KEY = 'mapConfiguration';

export const MAP_LOCATION_SETTINGS_KEY = 'mapLocationSettings';

export const APP_HELP_SEEN = 'helpSeen';

export const MAP_FORCE_CACHE_KEY = 'layout_';

export const APP_CONFIG_URL_KEY = 'mlo';

export const MAP_LOCATION_SETTINGS_URL_KEY = 'mls';
