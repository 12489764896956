<div class="main">
    <div class="title-row">
        <div class="title-row-title">
          <h3 class="name"><span i18n="@@deutschland">Deutschland</span> - {{ bedName }}</h3>
    
          <p class="numHospitals" *ngIf="data?.num_hospitals">({{ data?.num_hospitals }} <span i18n="tooltip | hospitals@reported">gemeldete Krankenhäuser</span>)</p>
        </div>
    </div>
  
    <mat-divider></mat-divider>

    <h4 *ngIf="!data?.last_updated" i18n="@@bedTooltipNoData">Es stehen momentan keine Bettenkapazitätsdaten für diesen Zeitpunkt zur Verfügung.</h4>

    <h4 *ngIf="data?.last_updated"><span i18n="@@tooltipHospitalLastUpdate">Letzter Stand: </span> <span>{{ data?.last_updated | date:'medium' }}</span></h4>

    <div class="chartcontainer">

        <div>
            <app-vega [spec]="spec"></app-vega>
        </div>

        <div class="explanation">
            {{ explanation }}
        </div>

    </div>

    <mat-hint class="hint" i18n="@@bedtooltipClickHint">Mit einem Mausklick können Sie das Symbol auf der Karte ausblenden.</mat-hint>

</div>