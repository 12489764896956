<h1 id="beschreibung-der-darstellung-der-fallzahlen" i18n="help| help@@title3">
    Beschreibung der Darstellung der Fallzahlen
</h1>
<div fxLayout="row" fxLayout.xs="column" class="visdescription">
    <div fxFlex class="helpimg">
        <img class="helpimg" src="/assets/help-dialog-cases-de.png" i18n-src="@@help-dialog-cases-image" style="max-width: 550px;" alt="Explanation Figure">
    </div>
    <div fxFlex class="helptxt">
        <p i18n="help| help@@helpdialog-cases-1">
            So wählen Sie die Darstellung der Positiv Getesteten- und Todesfälle (Fallzahlen):
        </p>
        <p i18n="help| help@@helpdialog-cases-2">
            Die Fallzahlen basieren auf den offiziellen Daten des Robert-Koch-Institutes (RKI; <a href="esri.de/corona">RKI-Datensätze</a>).
        </p>

        <p i18n="help| help@@helpdialog-cases-3"><u><b>Wo?</b> (A) Wählen zwischen der Darstellung für die:</u></p>
        <ol>
          <li i18n="help| help@@helpdialog-cases-3.1">Bundesländer (Länder), </li>
          <li i18n="help| help@@helpdialog-cases-3.2">Regierungsbezirke (Bezirke), </li>
          <li i18n="help| help@@helpdialog-cases-3.3">Landkreise (Kreise) </li>
        </ol>

        <p i18n="help| help@@helpdialog-cases-4"><u><b>Wann?</b> (B) Wählen Sie zwischen: </u></p>
        <ol>
          <li i18n="help| help@@helpdialog-cases-4.1">Gesamte verfügbare Zeitspanne</li>
          <li i18n="help| help@@helpdialog-cases-4.2">Letzten 24 Stunden</li>
          <li i18n="help| help@@helpdialog-cases-4.3">Letzten 72 Stunden</li>
        </ol>

        <p i18n="help| help@@helpdialog-cases-5"><u><b>Veränderung?</b> (C) Wählen Sie zwischend der Darstellung für: </u></p>
        <ol>
          <li i18n="help| help@@helpdialog-cases-5.1">Alle Fälle</li>
          <li i18n="help| help@@helpdialog-cases-5.2">Veränderung in den letzten 24/72 Stunden</li>
        </ol>

        <p i18n="help| help@@helpdialog-cases-6"><u><b>Fallart?</b> (D) Wählen zwischen der Darstellung für: </u></p>
        <ol>
          <li i18n="help| help@@helpdialog-cases-6.1">Positiv Getestet </li>
          <li i18n="help| help@@helpdialog-cases-6.2">Todesfälle</li>
        </ol>

        <p i18n="help| help@@helpdialog-cases-7"><u><b>Bezugsgröße?</b> (E) Wählen Sie zwischen der Darstellung für: </u></p>
        <ol>
          <li i18n="help| help@@helpdialog-cases-7.1">Alle (absolute) Fälle</li>
          <li i18n="help| help@@helpdialog-cases-7.2">Fälle pro 100.000 Einwohner</li>
        </ol>

        <p i18n="help| help@@helpdialog-cases-8">Wenn Sie mit der Maus über die Karte fahren, werden Ihnen für den jeweiligen gewählten Ort genaue Zahlen angezeigt (G). </p>

    </div>
</div>