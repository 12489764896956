<div class="title-row">
  <h1 mat-dialog-title i18n="@@shareTitle">
    Visualisierung Teilen
  </h1>

  <span class="spacer"></span>

  <button (click)="close()" mat-icon-button aria-label="Close Dialog">
    <mat-icon>close</mat-icon>
  </button>
</div>



<div mat-dialog-content>

  <mat-tab-group>
    <mat-tab label="URL">

      <mat-form-field style.fontSize="'8px'" class="urlfield">
        <mat-label>URL</mat-label>
        <textarea #urltextarea matInput onclick="this.focus();this.select()" readonly class="url" cdkTextareaAutosize
          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5">{{ url }}</textarea>
      </mat-form-field>
    
      <div class="copyline">
    
        <button mat-raised-button (click)="copyInputMessage(urltextarea)" i18n="@@shareCopy">Kopieren</button>
    
        <div *ngIf="urlcopied" i18n="@@shareCopied">Erfolgreich in die Zwischenablage kopiert</div>
    
      </div>

    </mat-tab>
    <mat-tab label="IFrame">

      <mat-form-field style.fontSize="'8px'" class="urlfield">
        <mat-label>URL</mat-label>
        <textarea #iframetextarea matInput onclick="this.focus();this.select()" readonly class="url" cdkTextareaAutosize
          #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5">{{ iframe }}</textarea>
      </mat-form-field>
    
      <div class="copyline">
    
        <button mat-raised-button (click)="copyInputMessage(iframetextarea)" i18n="@@shareCopy">Kopieren</button>
    
        <div *ngIf="urlcopied" i18n="@@shareCopied">Erfolgreich in die Zwischenablage kopiert</div>
    
      </div>

    </mat-tab>
  </mat-tab-group>
  
  
  <mat-divider style="margin: 10px 0;"></mat-divider>

  <h2 i18n="@@shareAdditionalOptions">Zusätzliche Optionen</h2>

  <app-region-selector [maxRegions]="null" [selectedRegions]="data.regions" (selectedRegionsChange)="data.regions = $event; update()"></app-region-selector>

  <div class="addOptions">

    <div class="toggleGroup">
      <h5 i18n="@@ebrakeShareLang">Sprache</h5>

      <mat-button-toggle-group
        class="btngrp"
        name="fontStyle"
        aria-label="Font Style"
        [value]="lang"
        (change)="lang = $event.value; update()"
      >
        <mat-button-toggle i18n="@@ebrakeShareLangAuto" [value]="'auto'">Automatisch</mat-button-toggle>
        <mat-button-toggle i18n="@@ebrakeShareLangDe" [value]="'de'"
                           >Deutsch
        </mat-button-toggle>
        <mat-button-toggle i18n="@@ebrakeShareLangEn" [value]="'en'"
                           >Englisch
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>

    <div class="checkbox">
      <mat-checkbox [(ngModel)]="showHeader" (change)="update()" i18n="@@ebrakeShareShowHeader">Kopfzeile anzeigen</mat-checkbox>
    </div>
  
    <div class="checkbox">
      <mat-checkbox [(ngModel)]="showRegions" (change)="update()" i18n="@@ebrakeShareShowRegions">Regionen-Auswahl anzeigen</mat-checkbox>
    </div>

    <div class="checkbox">
      <mat-checkbox [(ngModel)]="showFooter" (change)="update()" i18n="@@ebrakeShareShowFooter">Fusszeile anzeigen</mat-checkbox>
    </div>

    <div>
      <mat-form-field class="example-form-field">
        <mat-label i18n="@@ebrakeShareNumPastDays">Anzahl Tage in die Vergangenheit</mat-label>
        <input matInput type="number" [ngModel]="numPastDays"  (ngModelChange)="numPastDays = $event; update()">
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="example-form-field">
        <mat-label i18n="@@ebrakeShareNumFutureDays">Anzahl Tage in die Zukunft</mat-label>
        <input matInput type="number" [ngModel]="numFutureDays" (ngModelChange)="numFutureDays = $event; update()">
      </mat-form-field>
    </div>

  </div>
</div>
