<div [ngClass]="{ footer: true, vertical: mode === 'vertical' }">


    <a 
    mat-icon-button 
    (click)="openVideo()" 
    i18n-matTooltip="@@buttonPanelTooltipVideo" 
    matTooltip="Video über CoronaVis (Deutsch)"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      <mat-icon>live_tv</mat-icon>
    </a>

    <a 
    mat-icon-button 
    (click)="openHelp()" 
    i18n-matTooltip="@@buttonPanelTooltipHelp" 
    matTooltip="Hilfe"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      <mat-icon>help</mat-icon>
    </a>

    <a 
    mat-icon-button 
    (click)="openAbout()" 
    i18n-matTooltip="@@buttonPanelTooltipTeam" 
    matTooltip="Team"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      <mat-icon>group</mat-icon>
    </a>

    <a 
    *ngIf="!mo.hideInfobox"
    mat-icon-button 
    (click)="openShare()" 
    i18n-matTooltip="@@buttonPanelTooltipShare" 
    matTooltip="Teilen"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      <mat-icon>share</mat-icon>
    </a>

    <a
    *ngIf="(iconService.twitterLoaded$ | async)"
    mat-icon-button
    href="https://twitter.com/intent/tweet?screen_name=dbvis&button_hashtag=coronavis&ref_src=twsrc%5Etfw"
    target="_blank"
    i18n-matTooltip="@@buttonPanelTooltipTwitter" 
    matTooltip="Twittern sie über CoronaVis"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      <mat-icon svgIcon="twitter" aria-hidden="false" aria-label="Tweet #coronavis"></mat-icon>
    </a>

    <a
    *ngIf="(iconService.twitterLoaded$ | async) === false"
    mat-button
    href="https://twitter.com/intent/tweet?screen_name=dbvis&button_hashtag=coronavis&ref_src=twsrc%5Etfw"
    target="_blank"
    i18n-matTooltip="@@buttonPanelTooltipTwitter" 
    matTooltip="Twittern sie über CoronaVis"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      tweet #coronavis
    </a>

    <a
    *ngIf="(iconService.githubLoaded$ | async)"
    mat-icon-button
    href="https://github.com/dbvis-ukon/coronavis"
    target="_blank"
    i18n-matTooltip="@@buttonPanelTooltipGitHub" 
    matTooltip="Source Code auf GitHub"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      <mat-icon svgIcon="github" aria-hidden="false" aria-label="GitHub"></mat-icon>
    </a>

    <a
    *ngIf="(iconService.githubLoaded$ | async) === false"
    mat-button
    href="https://github.com/dbvis-ukon/coronavis"
    target="_blank"
    i18n-matTooltip="@@buttonPanelTooltipGitHub" 
    matTooltip="Source Code auf GitHub"
    [matTooltipPosition]="mode === 'vertical' ? 'right' : 'below'"
    >
      GitHub
    </a>

    <span class="spacer"></span>

    <app-language-switcher class="locale" *ngIf="!mo.hideInfobox"></app-language-switcher>
  </div>