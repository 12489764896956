import { Component } from '@angular/core';

@Component({
  selector: 'app-overlay-brand',
  templateUrl: './overlay-brand.component.html',
  styleUrls: ['./overlay-brand.component.less']
})
export class OverlayBrandComponent {

}
