<div class="container" [ngClass]="{ 'mobile': gridNumCols === 1 }">
    <div class="grid">
        <mat-grid-list [cols]="gridNumCols" rowHeight="1:1">
            <mat-grid-tile class="mytile" *ngFor="let d of dataBlobCases" [routerLink]="['/map', { mlo: d.moUrl, mls: d.mlsUrl}]">

                
                <div class="mapcontainer" *ngIf="d.data | withLoading | async as data">
                    <mat-spinner class="loading" *ngIf="data.loading"></mat-spinner>
                    <app-d3-choropleth-map class="map" *ngIf="data.value" [data]="data.value"></app-d3-choropleth-map>
                </div>


                <mat-grid-tile-footer class="footer">
                    <h4>{{ d.aggLevelFriendly | translate }}</h4>
                    <span class="spacer"></span>
                    <h4>{{ d.confDescription | translate }}</h4>
                </mat-grid-tile-footer>
                
            </mat-grid-tile>
        </mat-grid-list>
    </div>

    <div class="text">
        <h1>Übersicht Covid-19 Fallzahlen</h1>

        <div *ngIf="aggregatedRkiStatistics">
            <p class="stats" i18n="@@infoboxNumberOfCovidCases">{{ this.aggregatedRkiStatistics.cases | number }} bundesweit gemeldete Covid-19 Fälle</p>
            <p class="stats" i18n="@@infoboxNumberOfCovidDeaths">{{ this.aggregatedRkiStatistics.deaths | number }} bundesweit gemeldete Covid-19 Todesfälle</p>
            <p class="stats" i18n="@@infoboxLastUpdate">Letzter Stand: {{ this.aggregatedRkiStatistics.timestamp | date:'full' }}</p>
          </div>
    </div>
</div>