<div class="visdescription">
    <h1 id="interaktive-visualisierung-zur-analyse-von-intensivbettenkapazitäten-für-covid-19-patienten"
        i18n="help| help@@helpdialog-theproject-1">
        Freie und belegte Betten für Covid-19-Patienten in Deutschland: CoronaVIS- Interaktive Darstellung und Analyse
    </h1>

    <p i18n="help| help@@helpdialog-theproject-2">
        Hier finden Sie eine aktuelle Übersicht über die Bettenkapazitäten in Deutschland. Anhand der Information können Sie aktuelle Entwicklungen deutschlandweit verfolgen. Ziel des CoronaVIS-Tools ist es, dass mögliche Engpässe früh erkannt und die Verteilung von Patienten optimiert und freie Betten zeitnah genutzt werden können.
    </p>
    <p>
        <em i18n="help| help@@helpdialog-theproject-3">
            Die aktuellen Kapazitäten werden für die Darstellungen geladen. Der Vorgang kann einige Sekunden dauern. Die
            Darstellung funktioniert am Besten im Chrome-Browser.
        </em>
    </p>
    <p i18n="help| help@@helpdialog-theproject-4">
        CoronaVIS - die visuelle Darstellung von freien und belegten Betten für Covid-19-Patienten wird von der <a
            href="http://vis.uni-konstanz.de">Arbeitsgruppe Datenanalyse und Visualisierung</a> an der Universität
        Konstanz entwickelt und betreut.
    </p>
    <p i18n="help| help@@helpdialog-theproject-5">
        Die Darstellungen der Bettenkapazität in CoronaVIS basiert derzeit auf den öffentlichen Daten der Deutschen
        Interdisziplinären Vereinigung für Intensiv- und Notfallmedizin (DIVI) (<a
            href="https://www.divi.de/register/intensivregister?view=items">Link</a>) zur Bettenauslastung. Bitte
        beachten Sie, dass derzeit kein Zugriff auf die nichtöffentlichen exakten Kapazitätsdaten der DIVI erfolgen
        kann. Deshalb können Frühwarnungen vor Überlastungen derzeit nicht erfolgen. Diese Frühwarn-Funktionalität und
        weitere Funktionen sind bereits entwickelt und in CoronaVIS enthalten, jedoch aufgrund des fehlenden Zugangs zu
        den nichtöffentlichen exakten Kapazitätsdaten der DIVI sind diese derzeit nicht aktiviert.
    </p>
    <p i18n="help| help@@helpdialog-theproject-6">
        CoronaVIS ist kurzfristig einsatzfähig und nicht nur ein Forschungsprototyp. Es wird in Zusammenarbeit mit
        Experten kontinuierlich weiterentwickelt, um bestmögliche Unterstützung leisten zu können. Einen kurzen
        Videoüberblick finden Sie unter <a target="_blank"
            href="https://video.coronavis.dbvis.de">https://video.coronavis.dbvis.de</a>.
    </p>
    <p i18n="help| help@@helpdialog-theproject-7">
        Auf den folgenden Seiten stellen wir Ihnen die Funktionalitäten von CoronaVIS vor. Wenn Sie direkt CoronaVIS nutzen möchten, schließen Sie bitte dieses Fenster.
    </p>
</div>
